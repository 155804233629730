import {gql} from "@apollo/client";
import {CART_ITEM_FIELDS, CART_PRICE_FIELDS, FULL_LOCATION_FIELDS} from "./fragments";

export const CART_BADGE_GQL_DATA = gql`
  query CartBadge($sessionId: String, $userId: String) {
    cart(sessionId: $sessionId, userId: $userId) { id itemsCount }
  }
`;

export const FULL_CART_GQL_DATA = gql`
  ${CART_ITEM_FIELDS}
  ${CART_PRICE_FIELDS}
  query UserCart($sessionId: String, $userId: String) {
    cart(sessionId: $sessionId, userId: $userId) {
      id items { ...CartItemFields } fullPrice { ...CartPriceFields }
    }
  }
`;

export const FULL_AGENCY_GQL_DATA = gql`
  ${FULL_LOCATION_FIELDS}
  query Agency($agencyId: ID!) {
    agency: cmsAgency(agencyId: $agencyId) {
      id slug name nameComplement location { ...FullLocationFields } phone fax email website logo lead { value } coordinates
    }
  }
`;
