import React, {useEffect, useState} from "react";
import {useSearchParams} from "react-router-dom";
import {gql, useQuery} from "@apollo/client";
import {defineMessages, useIntl} from "react-intl";
import Skeleton from "react-loading-skeleton";
import {IconHome} from "@tabler/icons-react";
import {useIsMounted} from "@ct-react/core";
import {buildAgencyUrl} from "../../../shared/urls";
import {CMS_AGENCY_FIELDS} from "../../../shared/gql/fragments";
import {parseRefIdQueryParam} from "../../tools/search-params/open-article";
import NotFound from "../not-found";
import SeoHelmet from "../../components/seo-helmet";
import Wrapper from "../../components/layout/wrapper";
import InfoBox from "../../components/common/info-box";
import {MapContainer, MapMarker} from "../../code-splitting/map-implement";
import AgencyContactBox from "../../components/cards/agency-contact-box";
import AgencyForm from "../../components/forms/agency";
import "../common-cms.scss";
import "./open-agency.scss";

const transDefs = defineMessages({
  search: {
    id: "cms-agency-search-link",
    defaultMessage: "Parcourez tous les hébergements de l'agence"
  }
});

const AGENCY_GQL_DATA = gql`
  ${CMS_AGENCY_FIELDS}
  query GetAgency($agencyId: ID!) {
    agency: cmsAgency(agencyId: $agencyId) { ...CmsAgencyFields  }
  }
`;

const AgencyPage = () => {

  const isMounted = useIsMounted();
  const intl = useIntl();
  const [ searchParams ] = useSearchParams();

  // component states

  const [ failure, setFailure ] = useState<boolean>(!parseRefIdQueryParam(searchParams));
  const [ agencyId, setAgencyId ] = useState<string | undefined>(parseRefIdQueryParam(searchParams));

  useEffect(() => {
    if (!isMounted) return;
    const refId = parseRefIdQueryParam(searchParams);
    !!refId ? setAgencyId(refId) : setFailure(true);
  }, [ searchParams ]);

  // data loading

  const { data, loading } = useQuery(AGENCY_GQL_DATA, {
    variables: { agencyId },
    skip: !agencyId,
    onError: () => setFailure(true)
  });

  // rendering

  if (failure)
    return (<NotFound />);

  if (loading || !data.agency)
    return (
      <>
        <Wrapper.Centered as="main" className="rl-agency cms-page">
          <Wrapper.InnerTitle as="div" className="descripted-title">
            <Skeleton inline={true} width="50%" containerClassName="title flex-skeleton" />
            <div className="rl-lead flex-skeleton">
              <Skeleton count={4} />
              <Skeleton width="25%" />
            </div>
          </Wrapper.InnerTitle>
        </Wrapper.Centered>
        <Wrapper.Centered as="aside" className="rl-agency cms-map">
          <Skeleton className="map-box" inline={true} />
        </Wrapper.Centered>
      </>);

  return (
    <>
      <SeoHelmet title={data.agency.name}
                 description={data.agency.lead}
                 canonical={buildAgencyUrl(data.agency)} />


      <Wrapper.Centered as="main" className="rl-agency cms-page">

        <Wrapper.InnerTitle as="div" className="descripted-title">
          <h1>{data.agency.name}</h1>
          {!!data.agency.htmlContent &&
            <div className="rl-lead" dangerouslySetInnerHTML={{ __html: data.agency.htmlContent.value }} />
          }
          <InfoBox msg={intl.formatMessage(transDefs.search)}
                   tablerIcon={<IconHome />}
                   href={buildAgencyUrl(data.agency, true)} />
        </Wrapper.InnerTitle>

        <div className="inner-content contact">
          <AgencyContactBox data={data.agency} />
          <AgencyForm agencyId={data.agency.id} />
        </div>

      </Wrapper.Centered>

      {!!data.agency.coordinates &&
        <Wrapper.Centered as="aside" className="rl-resort cms-map">
          <MapContainer zoom={13} center={data.agency.coordinates} layersControlPosition="bottomleft">
            <MapMarker position={data.agency.coordinates} />
          </MapContainer>
        </Wrapper.Centered>
      }
    </>);

}

export default AgencyPage;
