import React, {useEffect, useState} from "react";
import {gql, useQuery} from "@apollo/client";
import {defineMessages, useIntl} from "react-intl";
import {useSearchParams} from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import {IconHome} from "@tabler/icons-react";
import {useIsMounted} from "@ct-react/core";
import {buildResortUrl} from "../../../shared/urls";
import {parseRefIdQueryParam} from "../../tools/search-params/open-article";
import NotFound from "../not-found";
import Wrapper from "../../components/layout/wrapper";
import SeoHelmet from "../../components/seo-helmet";
import InfoBox from "../../components/common/info-box";
import {MapContainer, MapMarker} from "../../code-splitting/map-implement";
import "../common-cms.scss";

const transDefs = defineMessages({
  search: {
    id: "cms-resort-search-link",
    defaultMessage: "Parcourez tous les hébergements de la station"
  }
});

const RESORT_GQL_DATA = gql`
  query GetResort($resortId: ID!) {
    resort: cmsResort(resortId: $resortId) {
      id slug name image lead { matchRequestedLang value } htmlContent { matchRequestedLang value } coordinates
    }
  }
`;

const parseRefIdParams = (params: URLSearchParams): number | undefined => {
  const result = parseRefIdQueryParam(params);
  return !!result ? +result : undefined;
}

const ResortPage = () => {

  const isMounted = useIsMounted();
  const intl = useIntl();
  const [ searchParams ] = useSearchParams();

  // component states

  const [ failure, setFailure ] = useState<boolean>(!parseRefIdParams(searchParams));
  const [ resortId, setResortId ] = useState<number | undefined>(parseRefIdParams(searchParams));

  useEffect(() => {
    if (!isMounted) return;
    const refId = parseRefIdParams(searchParams);
    !!refId ? setResortId(refId) : setFailure(true);
  }, [ searchParams ]);

  // data loading

  const { data, loading } = useQuery(RESORT_GQL_DATA, {
    variables: { resortId },
    skip: !resortId,
    onError: () => setFailure(true)
  });

  // rendering

  if (failure)
    return (<NotFound />);

  if (loading || !data.resort)
    return (
      <>
        <Wrapper.Centered as="main" className="rl-resort cms-page">
          <Wrapper.InnerTitle as="div" className="descripted-title">
            <Skeleton inline={true} width="50%" containerClassName="title flex-skeleton" />
            <div className="rl-lead flex-skeleton">
              <Skeleton count={4} />
              <Skeleton width="25%" />
            </div>
          </Wrapper.InnerTitle>
        </Wrapper.Centered>
        <Wrapper.Centered as="aside" className="rl-resort cms-map">
          <Skeleton className="map-box" inline={true} />
        </Wrapper.Centered>
      </>);

  return (
    <>
      <SeoHelmet title={data.resort.name}
                 description={data.resort.lead}
                 image={data.resort.image}
                 canonical={buildResortUrl(data.resort)} />


      <Wrapper.Centered as="main" className="rl-resort cms-page">
        <Wrapper.InnerTitle as="div" className="descripted-title">
          <h1>{data.resort.name}</h1>
          <div className="rl-lead" dangerouslySetInnerHTML={{ __html: data.resort.htmlContent.value }} />
          <InfoBox msg={intl.formatMessage(transDefs.search)}
                   tablerIcon={<IconHome />}
                   href={buildResortUrl(data.resort, true)} />
        </Wrapper.InnerTitle>
      </Wrapper.Centered>

      {!!data.resort.coordinates &&
        <Wrapper.Centered as="aside" className="rl-resort cms-map">
          <MapContainer zoom={10} center={data.resort.coordinates} layersControlPosition="bottomleft">
            <MapMarker position={data.resort.coordinates} />
          </MapContainer>
        </Wrapper.Centered>
      }
    </>);

}

export default ResortPage;
