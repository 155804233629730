import React from "react";
import {useIntl} from "react-intl";
import {isBookPeriod, isPriceWithExtras} from "@ct-react/core";
import {formatPeriod} from "../../../hooks/format";
import {ResumeBookChoice} from "../../../models/article";
import {Ratio} from "../../../models/images";
import RatioImage from "../../common/ratio-image";
import "./book-choice-resume.scss";

const BookChoiceResume = (props: ResumeBookChoice) => {

  const intl = useIntl();

  return (
    <div className="rla-choice-resume">
      {!!props.thumbnail &&
        <RatioImage images={props.thumbnail} alt={props.title} ratio={Ratio.SQUARE} />
      }
      <div className="metas">
        <span className="title">{props.title}</span>
        <span className="location">{props.location}</span>
        {!!props.period &&
          <span className="period">{formatPeriod(intl, props.period, true)}</span>
        }
      </div>
      {(isBookPeriod(props.period) && props.period.price !== "onDemand") &&
        <span className="price">
          {intl.formatNumber(isPriceWithExtras(props.period.price)
            ? props.period.price.fullAmount
            : props.period.price.amount, {
            style: "currency",
            currency: props.period.price.currency
          })}
        </span>
      }
    </div>);

}

export default BookChoiceResume;
