import React, {MouseEvent, useEffect, useMemo, useState} from "react";
import {defineMessages, IntlShape, useIntl} from "react-intl";
import {IconMapSearch, IconX} from "@tabler/icons-react";
import {
  autoUpdate, FloatingFocusManager,
  FloatingPortal,
  offset,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useMergeRefs
} from "@floating-ui/react";
import {classNames, useIsMounted} from "@ct-react/core";
import {Resort} from "../../../models/search";
import {
  MainSearchInputProps, SearchInputDefinitionProps,
  SearchInputSelectionProps,
  SearchInputViewProps
} from "./common";
import "./common.scss";
import "./region-input.scss"

const transDefs = defineMessages({
  label: { id: "search-form-region-input-label", defaultMessage: "Destination" },
  any: { id: "search-form-region-input-default", defaultMessage: "N'importe où" },
  placeholder: { id: "search-form-region-placeholder", defaultMessage: "Choisissez une destination" }
});

export const regionFormattedState = (intl: IntlShape, val: Resort | undefined, resortsList: Resort[]) => {
  if (!val) return intl.formatMessage(transDefs.any);
  return resortsList.find(r => r.id === val.id)?.name || "";
}

type InputExtraProps = { resortsList: Resort[] };
type InputViewProps = SearchInputDefinitionProps & InputExtraProps & SearchInputViewProps<Resort>;
type InputSelectionProps = InputExtraProps & SearchInputSelectionProps<Resort>;
type MainSearchRegionInputProps = MainSearchInputProps<Resort> & InputExtraProps;

const InputSelection = (
  {
    resortsList,
    value,
    onSelection
  }: InputSelectionProps) => (
    <div className="region-input-choice">
      <ul>
        {resortsList.map((r, i) =>
          <li key={i}
              className={classNames("selectable-region", { selected: r.id === value?.id})}
              onClick={(e) => onSelection(r as Resort, e)}>{r.name}</li>
        )}
      </ul>
    </div>);

const InputMobileView = (
  {
    name,
    open,
    toggle,
    resortsList,
    value,
    formattedValue,
    onValueChange
  }: InputViewProps) => {

  const intl = useIntl();

  // dom interactions

  const onSelection = (val: Resort | undefined, e?: MouseEvent) => {
    onValueChange(val);
    e?.stopPropagation();
  }

  const onReset = (e: MouseEvent) => {
    onValueChange(undefined);
    e.stopPropagation();
  }

  // rendering

  const wrapperClasses = classNames("input-wrap", name, { selection: open });

  const editionRendering = useMemo(() => (<>
    <h2 onClick={() => toggle(name, false)}>{intl.formatMessage(transDefs.label)}</h2>
    <InputSelection {...{ resortsList, value, onSelection }} />
  </>), [ resortsList, value ]);

  const resumeRendering = useMemo(() => (
    <>
      <div className="input-label ellipsed">{intl.formatMessage(transDefs.label)}</div>
      <div className={classNames("input-val-state", { empty: !value })}>
        <span className="label ellisped">{intl.formatMessage(transDefs.placeholder)}</span>
        <span className="value ellipsed">{formattedValue || intl.formatMessage(transDefs.any)}</span>
      </div>
      {!!value &&
        <button type="button" className="resetter" onClick={onReset}><IconX /></button>
      }
    </>
  ), [ value, formattedValue ]);

  return (
    <div tabIndex={0}
         className={wrapperClasses} onClick={() => !open && toggle(name, true)}>
      {open ? editionRendering : resumeRendering}
    </div>);

};

const InputDesktopView = (
  {
    name,
    open,
    toggle,
    resortsList,
    value,
    formattedValue,
    onValueChange
  }: InputViewProps) => {

  const intl = useIntl();

  // picker floating display

  const { x, y, reference, floating, strategy, context } = useFloating({
    open: open,
    onOpenChange: isOpen => toggle(name, isOpen),
    whileElementsMounted: autoUpdate,
    placement: "bottom-start",
    middleware: [ offset(8) ]
  })

  const { getReferenceProps, getFloatingProps } = useInteractions([
    useClick(context),
    useDismiss(context, { enabled: open, ancestorScroll: true })
  ]);

  // dom interaction

  const onSelection = (val: Resort | undefined, _?: MouseEvent) => {
    onValueChange(val);
    toggle(name, false);
  }

  // rendering

  const inputRef = useMergeRefs([ reference ]);
  const inputWrapperClasses = classNames("input-wrap", name);
  const pickerClasses = classNames("rf-searcher-input-dropdown", name);

  return (
    <>
      <div className={inputWrapperClasses}>
        <div ref={inputRef}
             {...getReferenceProps()}
             tabIndex={0}
             className="input-content inner-space">
          <IconMapSearch />
          <div className="input-val-wrap">
            <div className="input-label ellipsed">{intl.formatMessage(transDefs.label)}</div>
            <div className={classNames("input-val-state", { empty: !value })}>
              {!!formattedValue && <span className="value ellipsed">{formattedValue}</span>}
              <span className="label ellipsed">{intl.formatMessage(transDefs.any)}</span>
            </div>
          </div>
        </div>
        {!!formattedValue &&
          <button type="button" className="resetter" onClick={() => onValueChange(undefined)}><IconX /></button>
        }
      </div>
      <FloatingPortal>
        {open &&
          <FloatingFocusManager context={context}>
            <div ref={floating}
                 {...getFloatingProps()}
                 className={pickerClasses}
                 style={{ position: strategy, top: y ?? 0, left: x ?? 0 }}>
              <InputSelection {...{ resortsList, value, onSelection }} />
            </div>
          </FloatingFocusManager>
        }
      </FloatingPortal>
    </>);

};

const MainSearchRegionInput = (
  {
    name,
    open,
    toggle,
    mobileView,
    resortsList,
    value: initedValue,
    onValueChange
  }: MainSearchRegionInputProps) => {

  const isMounted = useIsMounted();
  const intl = useIntl();

  // component states

  const [ value, setValue ] = useState<Resort | undefined>(initedValue);

  useEffect(() => {
    if (!isMounted) return;
    onValueChange(value);
  }, [ value ]);

  const formattedValue = useMemo(() => {
    if (!value) return undefined;
    return regionFormattedState(intl, value, resortsList);
  }, [ resortsList, value ]);

  // rendering

  return mobileView
    ? <InputMobileView name={name}
                       open={open}
                       toggle={toggle}
                       resortsList={resortsList}
                       value={value}
                       formattedValue={formattedValue}
                       onValueChange={value => setValue(value)} />
    : <InputDesktopView name={name}
                        open={open}
                        toggle={toggle}
                        resortsList={resortsList}
                        value={value}
                        formattedValue={formattedValue}
                        onValueChange={value => setValue(value)} />
    ;

}

export default MainSearchRegionInput;
