import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {useParams} from "react-router";
import {useSearchParams} from "react-router-dom";
import {gql, useLazyQuery, useQuery} from "@apollo/client";
import {useIntl} from "react-intl";
import Skeleton from "react-loading-skeleton";
import {buildPriceWithExtras, classNames, useAppConfig, useIsMounted} from "@ct-react/core";
import {useNavigate} from "@ct-react/locale";
import {PERIODED_SEARCH_BOOKING_SUGGESTIONS_FIELDS, SUMMARY_FIELDS} from "../../shared/gql/fragments";
import {useDisplayMobileOnly, useDisplayUntilDesktop} from "../tools/breakpoints";
import {
  FilterSearchCriteria,
  isFlexBookingConfig,
  MainSearchCriteria,
  SearchPagination,
  SearchQuery,
  SearchViewConfig
} from "../models/search";
import {
  parseSearchParams,
  rebuildQuerySearchParamsFromFilter,
  rebuildQuerySearchParamsFromMain
} from "../tools/search-params/search";
import SeoHelmet from "../components/seo-helmet";
import Wrapper from "../components/layout/wrapper";
import MainSearchForm from "../components/search/main-search-form";
import {MapContainer, MapAsyncPopupMarker} from "../code-splitting/map-implement";
import ViewSwitcher from "../components/search/view-switcher";
import CountedResult from "../components/search/counted-result";
import FilterButton from "../components/search/filter-button";
import ResultPagination from "../components/search/result-pagination";
import Summary from "../components/cards/summary";
import "./common.scss";
import "./search.scss";

const AGENCY_CONTEXT_GQL_DATA = gql`
  query GetAgency($agencyId: ID!) {
    agency: cmsAgency(agencyId: $agencyId) { id name logo }
  }
`;

const SEARCH_GQL_DATA = gql`
  ${SUMMARY_FIELDS}
  ${PERIODED_SEARCH_BOOKING_SUGGESTIONS_FIELDS}
  query Search($where: RentalAccommodationSearchFilterInput!, $first: Int, $after: String, $last: Int, $before: String) {
    search(where: $where, first: $first, after: $after, last: $last, before: $before) {
      totalCount
      pageInfo { page pageCount previousCursor nextCursor lastCursor linkCursors { page cursor } }
      edges {
        cursor
        node { ...SummaryFields }
        bookingSuggestion { ...BookingSuggestionFields }
      }
      allMarkers { id coordinates priceOnDemand price { amount currency } }
    }
  }
`;

const rewriteGQLQuery = (query: SearchQuery) => {
  let { region, agency, period, features, ...result } = query as any;
  if (!!features) result = { ...result, ...features };
  if (!!region) result.regions = [ +region.id ];
  if (!!agency) result.agencyIds = [ agency ];
  if (!!period) {
    if (isFlexBookingConfig(period)) result.flexPeriod = period;
    else result.period = period;
  }
  return result;
};

const Search = ({ refContext }: { refContext: "agency" | "resort" }) => {

  const intl = useIntl();
  const isMounted = useIsMounted();
  const { options: { asAgency } } = useAppConfig();
  const navigate = useNavigate();
  const { slug: resortSlug } = useParams();

  // handle query definition

  const pageSize = useMemo(() => 20, []);

  const handleRefContextOnQuery = useCallback((current: URLSearchParams) => ({
    ...(!!asAgency) && { agency: asAgency.id },
    ...(refContext === "agency") && {
      agency: current.get("refId"),
      ...(current.has("region") && { region: { id: current.get("region") }})
    },
    ...(refContext === "resort" && !!resortSlug && current.has("refId")) && {
      region: { id: current.get("refId")!, slug: resortSlug }
    }
  }), []);

  const [ searchParams, setSearchParams ] = useSearchParams();
  const { query: initQuery, pagination: initPagination, viewConfig: initViewConfig} = parseSearchParams(searchParams);
  const [ pagination, setPagination ] = useState<SearchPagination>(initPagination);
  const [ viewConfig, setViewConfig ] = useState<SearchViewConfig>(initViewConfig);
  const [ query, setQuery ] = useState<SearchQuery>({
    ...initQuery,
    ...handleRefContextOnQuery(searchParams)
  } as SearchQuery);

  const paginationQueryParams = useMemo(() => {
    return pagination.direction === "before"
      ? { last: pageSize, before: pagination.cursor! }
      : { first: pageSize, ...(!!pagination.cursor) && { after: pagination.cursor! } };
  }, [ pagination ]);


  // use search params has state ref for any search criteria

  useEffect(() => {
    if (refContext !== "agency") return;
    getAgency({ ssr: false, variables: { agencyId: searchParams.get("refId") } }).then();
  }, [ refContext ]);

  useEffect(() => {
    if (!isMounted) return;
    const parsing = parseSearchParams(searchParams);
    setViewConfig(parsing.viewConfig);
    setPagination(parsing.pagination);
    setQuery({
      ...parsing.query,
      ...handleRefContextOnQuery(searchParams)
    } as SearchQuery);
  }, [ searchParams ]);

  // data loading

  const { loading, data } = useQuery(SEARCH_GQL_DATA, {
    ssr: false,
    variables: {
      where: rewriteGQLQuery(query),
      ...paginationQueryParams
    }
  });

  const [ getAgency, { loading: agencyLoading, data: agencyData }] = useLazyQuery(AGENCY_CONTEXT_GQL_DATA);

  // display events

  useEffect(() => {
    if (isMounted && viewConfig.mapView && withMobileViewSwitcher)
      mapRef.current?.scrollIntoView({ behavior: "smooth", block: "center" });
  }, [ viewConfig.mapView ]);

  // dom interactions

  const onSearch = (newQuery: MainSearchCriteria) => {
    const newParams = new URLSearchParams(searchParams);
    rebuildQuerySearchParamsFromMain(newParams, newQuery);
    (refContext === "agency" && !!newQuery.region) && newParams.set("region", newQuery.region.id.toString());
    (refContext === "agency" && newParams.has("region") && !newQuery.region) && newParams.delete("region");

    const needRouting = (refContext === "resort" && query.region?.id !== newQuery.region?.id);
    if (!needRouting) {
      setSearchParams(newParams);
      return;
    }

    newParams.delete("refId");
    let queryParams = !!newQuery.region ? `refId=${newQuery.region.id}` : "";
    if (!!newQuery.region && newParams.size > 0) queryParams += "&";
    if (newParams.size > 0) queryParams += newParams.toString();
    navigate(`/resorts/${newQuery.region?.slug ?? "any"}/search${queryParams.length > 0 ? `?${queryParams}` : ""}`);
  }

  const onFilter = (newQuery: FilterSearchCriteria) => {
    const newParams = new URLSearchParams(searchParams);
    rebuildQuerySearchParamsFromFilter(newParams, newQuery);
    setSearchParams(newParams);
  }

  const onSwitchView = (mapView: boolean) => {
    if (!mapView && searchParams.has("map_view")) searchParams.delete("map_view");
    else searchParams.set("map_view", String(mapView));
    setSearchParams(searchParams, { replace: true });
  }

  const onNavPageChange = () => window.scrollTo({ top: 0, left: 0, behavior: "smooth"});

  // rendering

  const isMobile = useDisplayMobileOnly();
  const untilDesktop = useDisplayUntilDesktop();
  const withMobileViewSwitcher = isMounted && untilDesktop;
  const mapRef = useRef<HTMLElement>(null);

  return (
    <>

      <SeoHelmet title={intl.formatMessage(
        { id: "seo-title-search", defaultMessage: "{success, select, false {Recherche...} other {Recherche: {count, plural, =0 {aucun résultat} =1 {# résultat} other {# résultats}}}}" },
        { success: String(!loading), count : data?.search?.totalCount || 0 }
      )} />

      <Wrapper.Centered as="main"
                        className="rl-search">

        <div className="rls-form">
          {refContext === "agency" &&
            <h3>
              {(agencyLoading || !agencyData?.agency)
                ? <Skeleton width={isMobile ? "75%" : "35%"} />
                : agencyData.agency.name
              }
            </h3>
          }
          <MainSearchForm agencyId={agencyData?.agency.id || asAgency?.id}
                          criteria={query}
                          onCriteriaChange={onSearch} />
        </div>

        <div className={classNames("rls-result-wrapper", {"map-only": viewConfig.mapView })}>

          <section className="rls-result-list">
            <div className={classNames("rls-result-header", { empty: !loading && data?.search?.totalCount === 0 })}>
              <CountedResult loading={loading} data={data?.search?.totalCount} />
              <FilterButton loading={loading || agencyLoading}
                            agencyId={agencyData?.agency.id || asAgency?.id}
                            countedResult={data?.search?.totalCount}
                            data={query}
                            onDataChange={onFilter} />
            </div>
            {!loading
              ? <div className="rg-wrapped-list">
                {
                  data.search.edges.map(edge =>
                    <Summary key={edge.node.id}
                             {...edge.node}
                             bookingSuggestion={edge.bookingSuggestion} />)
                }
              </div>
              : <Skeleton containerClassName="rg-wrapped-list"
                          className="rg-card-skeleton"
                          count={pageSize}
                          inline={true} />
            }
            {!!data?.search &&
              <ResultPagination className="rls-result-footer"
                                urlParams={searchParams}
                                data={{ pageSize, ...data.search.pageInfo }}
                                onPageChange={onNavPageChange} />
            }
          </section>

          <aside ref={mapRef} className="rls-result-map">
            <MapContainer center={[ 46.229301769607815, 7.364792654431519 ]}
                          zoom={9}
                          zoomControlPosition="topright"
                          layersControlPosition={isMobile ? "topleft" : "bottomleft"}
                          clusterize={true}
                          fitBounds={isMobile ? 25 : 100}>
              {!loading && data.search.allMarkers.map((m, i) =>
                <MapAsyncPopupMarker key={i}
                                     contextQuery={rewriteGQLQuery(query)}
                                     {...m} />
              )}
            </MapContainer>
            {!withMobileViewSwitcher &&
              <ViewSwitcher mapView={viewConfig.mapView} onChange={onSwitchView} />
            }
          </aside>

        </div>

      </Wrapper.Centered>

      {withMobileViewSwitcher &&
        <ViewSwitcher forMobile={true} mapView={viewConfig.mapView} onChange={onSwitchView} />
      }

    </>);

}

export default Search;
