import React from "react";
import Skeleton from "react-loading-skeleton";
import {useIntl} from "react-intl";
import {SkeletonableProps} from "../../tools/components";
import "./counted-result.scss";

type CountedResultProps = SkeletonableProps<number> & {
  value?: number;
  overStep?: number;
};

const CountedResult = (
  {
    loading,
    data: value,
    overStep = 500
  }: CountedResultProps) => {

  const intl = useIntl();

  if (loading) return <Skeleton containerClassName="rls-search-counted-skeleton" />
  if (typeof value === "undefined") return null;

  if (value === 0) return (
    <div>
      <h1 className="rls-search-counted-result empty">
        {intl.formatMessage({
          id: "search-counted-no-result",
          defaultMessage: "Aucun résultat"
        })}
      </h1>
      <span className="rls-search-counted-helper">
        {intl.formatMessage({
          id: "seatch-counted-no-result-helper",
          defaultMessage: "Ajustez votre recherche ou modifiez certains de vos filtres."
        })}
      </span>
    </div>);

  return (
    <h1 className="rls-search-counted-result">
      {value > overStep
        ? intl.formatMessage({
          id: "search-counted-result-overed",
          defaultMessage: "Plus de {overStep} résultats"
        }, { overStep })
        : intl.formatMessage({
          id: "search-counted-result",
          defaultMessage: "{value, plural, =1 {# résultat} other {# résultats}}"
        }, { value })
      }
    </h1>);

}

export default CountedResult;
