import React from "react";
import {SkeletonableProps} from "../../tools/components";
import {useIntl} from "react-intl";
import {useNavigate} from "@ct-react/locale";
import {classNames} from "@ct-react/core";
import Skeleton from "react-loading-skeleton";
import {buildResortUrl} from "../../../shared/urls";
import {Resort} from "../../models/resort";
import {Button, ClampText} from "../common/minimals";
import "./resort.scss";

type ResortCardProps = SkeletonableProps<Resort>;

const ResortCard = (
  {
    loading,
    className,
    data
  }: ResortCardProps) => {

  const intl = useIntl();
  const navigate = useNavigate();

  const wrapperClasses = classNames("r-card", className, { "no-image": !data?.image });

  if (loading || !data)
    return (
      <div className={wrapperClasses}>
        <h3><Skeleton width="65%" /></h3>
        <div className="about">
          <Skeleton inline={true} count={3} />
          <Skeleton inline={true} width="50%" />
        </div>
      </div>);

  const url = buildResortUrl(data);

  return (
    <div className={wrapperClasses}>
      <h3>{data.name}</h3>
      {!!data.image &&
        <div className="r-img ratio-16-9">
          <img src={data.image} alt={data.name} />
        </div>
      }
      <div className="about">
        {!!data.lead?.value &&
          <ClampText text={data.lead.value} maxChar={160} expandable={false} />
        }
        <Button className="more tertiary small bolder"
                onClick={() => navigate(url)}>
          {intl.formatMessage({ id: "generics-more-open", defaultMessage: "En savoir plus" })}
        </Button>
      </div>
    </div>);

};

export default ResortCard;
