import {useCallback, useEffect, useMemo, useState} from "react";
import {useAppConfig} from "@ct-react/core";

type FetcherStateResult<T> = { data: T | undefined, loading: boolean, error: string | undefined; };
type LazyFetcherResult<T> = [ () => Promise<T>, FetcherStateResult<T> ];
type PostFetcherResult<TBody, TResult = TBody> = [ (val: TBody) => Promise<TResult>, FetcherStateResult<TResult>]

const useFetcherTools = <TResult, TBody = TResult>(path: string) => {

  const { baseUrl } = useAppConfig();

  const url = useMemo(() => `${baseUrl}/local-api${path.charAt(0) === "/" ? path : `/${path}`}`, [ path ]);

  const loader = useCallback((): Promise<TResult> => {
    return fetch(url)
      .then(res => {
        if (res.status >= 400) throw new Error(`fetching failure on local path: ${path}`);
        return res.json() as TResult;
      });
  }, [ url ]);

  const updater = useCallback((val: TBody): Promise<TResult> => {
    const fetchOptions = {
      method: "POST",
      body: JSON.stringify(val),
      headers: new Headers({ "content-type": "application/json" })
    };
    return fetch(url, fetchOptions)
      .then(res => {
        if (res.status >= 400) throw new Error(`update failure on local path : ${path}`);
        return res.json() as TResult;
      })
  }, [ url ]);

  return { loader, updater };

}

export const useLocalFetcher = <T>(path: string): FetcherStateResult<T> => {

  const { loader: fetchLoader } = useFetcherTools<T>(path);

  const [ data, setData ] = useState<T | undefined>(undefined);
  const [ loading, setLoading ] = useState<boolean>(false);
  const [ error, setError ] = useState<string | undefined>(undefined);

  useEffect(() => {
    setLoading(true);
    fetchLoader()
      .then(result => {
        !!error && setError(undefined);
        setData(result);
        return result;
      })
      .finally(() => setLoading(false));
  }, []);

  return { data, loading, error };

}

export const useLazyLocalFetcher = <T>(path: string): LazyFetcherResult<T> => {

  const { loader: fetchLoader } = useFetcherTools<T>(path);

  const [ data, setData ] = useState<T | undefined>(undefined);
  const [ loading, setLoading ] = useState<boolean>(false);
  const [ error, setError ] = useState<string | undefined>(undefined);

  const loader = useCallback((): Promise<T> => {
    setLoading(true);
    return fetchLoader()
      .then(result => {
        !!error && setError(undefined);
        setData(result);
        return result;
      })
      .finally(() => setLoading(false));
  }, [ path ]);

  return [ loader, { data, loading, error } ];

}

export const usePostLocalFetcher = <TBody, TResult>(path: string): PostFetcherResult<TBody, TResult> => {

  const { updater: fetchUpdater } = useFetcherTools<TResult, TBody>(path);

  const [ data, setData ] = useState<TResult | undefined>(undefined);
  const [ loading, setLoading ] = useState<boolean>(false);
  const [ error, setError ] = useState<string | undefined>(undefined);

  const updater = useCallback((val: TBody): Promise<TResult> => {
    setLoading(true);
    return fetchUpdater(val)
      .then(result => {
        !!error && setError(undefined);
        setData(result);
        return result;
      })
      .finally(() => setLoading(false));
  }, [ path ]);

  return [ updater, { data, loading, error } ];
}
