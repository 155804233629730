import React, { useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { IconHome, IconStar } from "@tabler/icons-react";
import { classNames } from "@ct-react/core";
import { LocaleLink } from "@ct-react/locale";
import { buildArticleUrl } from "@shared/urls";
import { Comment as CommentModel } from "../../models/comment";
import { DataProps } from "../../tools/components";
import { Button } from "../common/minimals";
import "./comment.scss";

type CommentProps = DataProps<CommentModel> & {
  moreable?: () => void;
}

const Comment = (
  {
    className,
    moreable: onMoreableClick,
    data
  }: CommentProps) => {

  const intl = useIntl();
  const commentRef = useRef<HTMLParagraphElement>(null);
  const [ moreable, setMoreable ] = useState<boolean>(false);

  useEffect(() => {
    if (!commentRef.current) return;
    setMoreable(commentRef.current.scrollHeight > commentRef.current.clientHeight);
  }, []);

  const wrapperClasses = classNames("rw-card", className);

  return (
    <div className={wrapperClasses}>
      <div className="client">
        <div>
          <div className="name">
            {data.customerName}
            <span className="rate">
              <IconStar />
              {intl.formatNumber(data.averageRate, { minimumFractionDigits: 1, maximumFractionDigits: 1 })}
            </span>
          </div>
          <div className="date">
            {intl.formatDate(data.date.toDate(), { month: "long", year: "numeric" })}
          </div>
        </div>
      </div>

      <p ref={commentRef}
         className="comment">{data.comment}</p>

      {(!!onMoreableClick && (moreable || data.withAnswer)) &&
        <Button type="button"
                className="link small to-modal"
                onClick={() => onMoreableClick()}>
          {intl.formatMessage({ id: "generics-more-open", defaultMessage: "En savoir plus" })}
        </Button>
      }

      {!!data.article &&
        <LocaleLink to={buildArticleUrl(data.article)} className="article">
          <div className="picto"><IconHome /></div>
          <div className="detail">
            <span className="title">{data.article.title.value}</span>
            {!!data.article.resort && <span className="region">{data.article.resort.name}</span> }
          </div>
        </LocaleLink>
      }
    </div>);

}

export default Comment;
