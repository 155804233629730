import {IntlShape} from "react-intl";
import moment from "moment";

export const formatPeriod = (intl: IntlShape, from: moment.Moment, to: moment.Moment, margin?: number) => {
  const period = from.isSame(to, "month")
    ? `${intl.formatDate(from.toDate(), { day: "numeric" })} - ${intl.formatDate(to.toDate(), { day: "numeric" })} ${intl.formatDate(from.toDate(), { month: "short" })}`
    : `${intl.formatDate(from.toDate(), { day: "numeric", month: "short" })} - ${intl.formatDate(to.toDate(), { day: "numeric", month: "short" })}`;
  if (!!margin)
    return `${period} ±${margin}`;
  return period;
}

export const formatDiscountPeriod = (intl: IntlShape, from: moment.Moment, to: moment.Moment) => {
  const sameMonth = from.isSame(to, "month");
  const fromStr = intl.formatDate(from.toDate(), { day: "numeric", ...(!sameMonth) && { month: "short" } });
  const toStr = intl.formatDate(to.toDate(), { day: "numeric", month: "short" });
  return intl.formatMessage({
    id: "generics-discount-period",
    defaultMessage: "À partir du {from} au {to}"
  }, { from: fromStr, to: toStr });
}
