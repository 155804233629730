import fetch from "isomorphic-fetch";
import {ApolloClient, ApolloLink, createHttpLink, from, InMemoryCache} from "@apollo/client";
import {ApolloClientOptions} from "@apollo/client/core/ApolloClient";
import {onError} from "@apollo/client/link/error";
import {Locale} from "@ct-react/locale";
import {AppContext} from "../models/app-context";

const buildConsumerMiddleware = (appName: string, locale?: Locale) => {
  return new ApolloLink((operation, forward) => {
    operation.setContext(({ headers = {} }) => ({
      headers: {
        ...headers,
        "GQL-Consumer-AppName": appName,
        ...(!!locale) && { "GQL-Consumer-Lang": locale.basename }
      }
    }));
    return forward(operation);
  });
}

const buildApolloClient = (context: AppContext, ssrMode: boolean = false, locale?: Locale, restoringCache?: any) => {
  const cache = new InMemoryCache();
  if (!!restoringCache) cache.restore(JSON.parse(restoringCache));

  return new ApolloClient({
    ssrMode,
    connectToDevTools: !ssrMode && context.env === "development",
    link: from([
      onError(e => console.error("gql error", e)),
      buildConsumerMiddleware(context.gqlAppName, locale),
      createHttpLink({ uri: context.gqlClientUrl, fetch })
    ]),
    cache
  } as unknown as ApolloClientOptions<InMemoryCache>);
}

export default buildApolloClient;
