import React from "react";
import {useIntl} from "react-intl";
import {IconCalendar} from "@tabler/icons-react";
import {AnyPeriod, BookPeriod, classNames, Period} from "@ct-react/core";
import {bookingTranslations} from "../../../i18n/sharable-defs";
import "./period-input-resume.scss";

type PeriodInputResumeProps = {
  period?: BookPeriod;
  compactView?: boolean;
};

const PeriodInputResume = (
  {
    period,
    compactView = false
  }: PeriodInputResumeProps) => {

  const intl = useIntl();
  const wrapperClasses = classNames("r-pi-in-out-group", { compact: compactView! });

  return (
    <div className={wrapperClasses}>
      <div className="r-pi-field r-pi-arrival">
        <div className="r-pi-input">
          <label htmlFor="book-arrival">
            {intl.formatMessage(bookingTranslations.checkIn)}
          </label>
          <input type="text"
                 id="book-arrival"
                 placeholder={intl.formatMessage(bookingTranslations.periodPlaceholder)}
                 value={!!period?.start ? intl.formatDate(period.start.toDate(), { year: "numeric", month: "2-digit", day: "2-digit" }) : ""}
                 readOnly={true} />
          {!compactView && <IconCalendar />}
        </div>
      </div>
      <div className="r-pi-field r-pi-departure">
        <div className="r-pi-input">
          <label htmlFor="book-departure">
            {intl.formatMessage(bookingTranslations.checkOut)}
          </label>
          <input type="text"
                 id="book-departure"
                 placeholder={intl.formatMessage(bookingTranslations.periodPlaceholder)}
                 value={!!period?.end ? intl.formatDate(period.end.toDate(), { year: "numeric", month: "2-digit", day: "2-digit" }) : ""}
                 readOnly={true} />
          {!compactView && <IconCalendar />}
        </div>
      </div>
    </div>);

}

export default PeriodInputResume
