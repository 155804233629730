import React from "react";
import {defineMessages, useIntl} from "react-intl";
import {gql, useQuery} from "@apollo/client";
import Wrapper from "../../components/layout/wrapper";
import SeoHelmet from "../../components/seo-helmet";
import ResortCard from "../../components/cards/resort";
import "../common-cms.scss";
import {Resort} from "../../models/resort";

const transDefs = defineMessages({
  title: {
    id: "seo-title-resorts",
    defaultMessage: "De l'évasion au gré des saisons..."
  },
  desc: {
    id: "seo-desc-resorts",
    defaultMessage: "Situées au coeur des alpes valaisannes, vaudoises, fribourgeoises et de l'Oberland bernois, plus de 30 stations vous accueillent pour un séjour en vous proposant des locations d'appartements et de chalets de vacances, du studio au chalet de luxe."
  },
  descTwo: {
    id: "seo-desc-resorts-two",
    defaultMessage: "Faites votre choix et réservez dès maintenant!"
  }
});

const GET_RESORTS = gql`
  query GetResorts {
    resorts: cmsResorts { id slug name image lead { value } }
  }
`;

const ResortsPage = () => {

  const intl = useIntl();

  const { loading, data } = useQuery<{ resorts: Resort[] }>(GET_RESORTS);
  const resorts = data?.resorts || undefined;

  return (
    <Wrapper.Centered as="main" className="rl-resorts cms-grid">

      <SeoHelmet title={intl.formatMessage(transDefs.title)}
                 description={intl.formatMessage(transDefs.desc)}
                 canonical="/resorts" />

      <Wrapper.InnerTitle as="div" className="descripted-title">
        <h1>{intl.formatMessage(transDefs.title)}</h1>
        <div className="rl-lead">
          <p>{intl.formatMessage(transDefs.desc)}</p>
          <p>{intl.formatMessage(transDefs.descTwo)}</p>
        </div>
      </Wrapper.InnerTitle>

      <section className="cards-wrapper">
        {(loading || !resorts)
          ? [ ...Array(10) ].map((_, i) => <ResortCard key={i} loading={true} />)
          : resorts.map((r, i) => <ResortCard key={i} loading={false} data={r} />)
        }
      </section>

    </Wrapper.Centered>);

};

export default ResortsPage;
