import React, {forwardRef, useEffect, useState} from "react";
import {FormatNumberOptions, useIntl} from "react-intl";
import {useIsMounted} from "@ct-react/core";
import {CartPrice} from "../../models/cart";
import "./payment-type-choice.scss";

export type PaymentChoiceValue = "full" | "partial";

type PaymentTypeChoiceFormProps = {
  cartPrice: CartPrice;
  value: PaymentChoiceValue;
  onChange: (val: PaymentChoiceValue) => void;
};

const PaymentTypeChoiceForm = (
  {
    cartPrice : price,
    value: defaultValue,
    onChange
  }: PaymentTypeChoiceFormProps, forwardedRef) => {

  const isMounted = useIsMounted();
  const intl = useIntl();
  const currencyOption = { style: "currency", currency: price.currency } as FormatNumberOptions;

  const [ activePartial, setActivePartial ] = useState<boolean>(price.downPayment !== price.amount);
  const [ value, setValue ] = useState<PaymentChoiceValue>(defaultValue);

  useEffect(() => {
    const newVal = price.downPayment !== price.amount;
    if (newVal === activePartial)
      return;
    setActivePartial(newVal);
  }, [ price ]);

  useEffect(() => {
    if (!isMounted) return;
    onChange(value)
  }, [ value ]);

  return (
    <form ref={forwardedRef}
          className="payment-choice">
      <label className="grouped-radio">
        <input type="radio"
               name="choice"
               id="full"
               value="full"
               checked={value === "full"}
               onChange={e => setValue(e.target.value as PaymentChoiceValue)} />
        <span className="full-radio">
          <span className="label">
            {intl.formatMessage({
              id: "cart-process-payment-choice-full-label",
              defaultMessage: "Payer le montant total"
            })}
          </span>
          <label htmlFor="full">{intl.formatNumber(price.amount, currencyOption)}</label>
          <span className="checkmark"/>
          <span className="detail">
            {intl.formatMessage({
              id: "cart-process-payment-choice-full-detail",
              defaultMessage: "Payez le total maintenant et vous aurez terminé."
            })}
          </span>
        </span>
      </label>
      <label className="grouped-radio">
        <input type="radio"
               name="choice"
               id="partial"
               value="partial"
               checked={value === "partial"}
               onChange={e => setValue(e.target.value as PaymentChoiceValue)}
               disabled={!activePartial} />
        <span className="full-radio">
          <span className="label">
            {intl.formatMessage({
              id: "cart-process-payment-choice-partial-label",
              defaultMessage: "Payer une partie maintenant et l'autre plus tard"
            })}
          </span>
          {activePartial &&
            <label htmlFor="partial">{intl.formatNumber(price.downPayment, currencyOption)}</label>
          }
          <span className="checkmark"/>
          {activePartial &&
            <span className="detail">
              <span className="block">
                {intl.formatMessage({
                  id: "cart-process-payment-choice-partial-detail",
                  defaultMessage: "Payez {down} maintenant et le solde ({diff}) vous sera prélevé ultérieurement."
                }, {
                  down: intl.formatNumber(price.downPayment, currencyOption),
                  diff: intl.formatNumber(price.amount - price.downPayment, currencyOption)
                })}
              </span>
              <span className="block">
                {intl.formatMessage({
                  id: "cart-process-payment-choice-partial-detail-cost",
                  defaultMessage: "Aucun frais supplémentaires."
                })}
              </span>
            </span>
          }
          {!activePartial &&
            <span className="detail">
              <span className="block">
                {intl.formatMessage({
                  id: "cart-process-payment-choice-partial-disabled",
                  defaultMessage: "Cette option n'est pas disponible pour cette réservation."
                })}
              </span>
            </span>
          }
        </span>
      </label>
    </form>);

}

export default forwardRef(PaymentTypeChoiceForm);
