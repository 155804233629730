import {IntlShape} from "react-intl";
import {AnyPeriod, isPeriodValid} from "@ct-react/core";

export const formatPeriod = (intl: IntlShape, period: AnyPeriod, withYear: boolean = false): string => {

  if (!isPeriodValid(period))
    return "";

  const startDate = period.start!.toDate();
  const endDate = period.end!.toDate();

  if (period.start!.isSame(period.end, "month")) {
    const startDay = intl.formatDate(startDate, { day: "numeric" });
    const endDay = intl.formatDate(endDate, { day: "numeric" });
    const month = intl.formatDate(startDate, { month: "short" });
    const year = intl.formatDate(startDate, { year: "numeric" });
    return `${startDay} - ${endDay} ${month}${withYear ? ` ${year}` : ``}`;
  }

  if (period.start!.isSame(period.end, "year")) {
    const startDay = intl.formatDate(startDate, { day: "2-digit", month: "short" });
    const endDay = intl.formatDate(endDate, { day: "2-digit", month: "short" });
    const year = intl.formatDate(startDate, { year: "numeric" });
    return `${startDay} - ${endDay}${withYear ? ` ${year}` : ""}`;
  }

  const startDay = intl.formatDate(startDate, { day: "2-digit", month: "short", ...(withYear) && { year: "numeric" } });
  const endDay = intl.formatDate(endDate, { day: "2-digit", month: "short", ...(withYear) && { year: "numeric" } });
  return `${startDay} - ${endDay}`;

}

export const resumeText = (text: string, maxChar: number = 160) =>
  text.length <= maxChar ? text : `${text.slice(0, maxChar - 3)}...`;
