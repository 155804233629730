import React, { CSSProperties, forwardRef } from "react";
import { useIntl } from "react-intl";
import { IconStar } from "@tabler/icons-react";
import { classNames } from "@ct-react/core";
import { SkeletonableProps } from "../../../tools/components";
import "./reviews.scss";

interface ReviewsData {
  averageRate: number;
  rates: {
    average: number;
    welcome: number;
    reservation: number;
    overallComfort: number;
    bedsComfort: number;
    cleanliness: number;
    accuracy: number;
    location: number;
    satisfaction: number;
    value: number;
  };
}

type ReviewsProps = SkeletonableProps<ReviewsData>;

const Reviews = forwardRef<HTMLDivElement, ReviewsProps>((
  {
    className,
    data
  }: ReviewsProps, forwardedRef) => {

  const intl = useIntl();
  const ratingFormatter = (value: number): string => intl.formatNumber(value, {
    minimumFractionDigits: 1,
    maximumFractionDigits: 1
  });

  if (!data) return null;

  return (
    <div ref={forwardedRef}
         className={classNames("rla-reviews", className)}>

      <div className="title">
        <h2>
          {intl.formatMessage({
            id: "booking-reviews-title",
            defaultMessage: "Évaluations"
          })}
          <span className="average">
            <IconStar />
            {ratingFormatter(data!.averageRate)}
          </span>
        </h2>
      </div>

      <div className="ratings-list">
        <div className="a-rating">
          <span className="criteria">
            {intl.formatMessage({
              id: "booking-ratings-welcome",
              defaultMessage: "Accueil"
            })}
          </span>
          <div className="value">
            <div className="bar">
              <span style={{ "--bar-rating": data!.rates.welcome } as CSSProperties} />
            </div>
            <span>{ratingFormatter(data!.rates.welcome)}</span>
          </div>
        </div>
        <div className="a-rating">
          <span className="criteria ellipsed">
            {intl.formatMessage({
              id: "booking-ratings-reservation",
              defaultMessage: "Réservation"
            })}
          </span>
          <div className="value">
            <div className="bar">
              <span style={{ "--bar-rating": data!.rates.reservation } as CSSProperties} />
            </div>
            <span>{ratingFormatter(data!.rates.reservation)}</span>
          </div>
        </div>
        <div className="a-rating">
          <span className="criteria ellipsed">
            {intl.formatMessage({
              id: "booking-ratings-overall-comfort",
              defaultMessage: "Confort général"
            })}
          </span>
          <div className="value">
            <div className="bar">
              <span style={{ "--bar-rating": data!.rates.overallComfort } as CSSProperties} />
            </div>
            <span>{ratingFormatter(data!.rates.overallComfort)}</span>
          </div>
        </div>
        <div className="a-rating">
          <span className="criteria ellipsed">
            {intl.formatMessage({
              id: "booking-ratings-beds-comfort",
              defaultMessage: "Confort des lits"
            })}
          </span>
          <div className="value">
            <div className="bar">
              <span style={{ "--bar-rating": data!.rates.bedsComfort } as CSSProperties} />
            </div>
            <span>{ratingFormatter(data!.rates.bedsComfort)}</span>
          </div>
        </div>
        <div className="a-rating">
          <span className="criteria ellipsed">
            {intl.formatMessage({
              id: "booking-ratings-cleanliness",
              defaultMessage: "Propreté"
            })}
          </span>
          <div className="value">
            <div className="bar">
              <span style={{ "--bar-rating": data!.rates.cleanliness } as CSSProperties} />
            </div>
            <span>{ratingFormatter(data!.rates.cleanliness)}</span>
          </div>
        </div>
        <div className="a-rating">
          <span className="criteria ellipsed">
            {intl.formatMessage({
              id: "booking-ratings-accuracy",
              defaultMessage: "Précision"
            })}
          </span>
          <div className="value">
            <div className="bar">
              <span style={{ "--bar-rating": data!.rates.accuracy } as CSSProperties} />
            </div>
            <span>{ratingFormatter(data!.rates.accuracy)}</span>
          </div>
        </div>
        <div className="a-rating">
          <span className="criteria ellipsed">
            {intl.formatMessage({
              id: "booking-ratings-location",
              defaultMessage: "Lieu"
            })}
          </span>
          <div className="value">
            <div className="bar">
              <span style={{ "--bar-rating": data!.rates.location } as CSSProperties} />
            </div>
            <span>{ratingFormatter(data!.rates.location)}</span>
          </div>
        </div>
        <div className="a-rating">
          <span className="criteria ellipsed">
            {intl.formatMessage({
              id: "booking-ratings-satisfaction",
              defaultMessage: "Satisfaction"
            })}
          </span>
          <div className="value">
            <div className="bar">
              <span style={{ "--bar-rating": data!.rates.satisfaction } as CSSProperties} />
            </div>
            <span>{ratingFormatter(data!.rates.satisfaction)}</span>
          </div>
        </div>
        <div className="a-rating">
          <span className="criteria ellipsed">
            {intl.formatMessage({
              id: "booking-ratings-value",
              defaultMessage: "Qualité-prix"
            })}
          </span>
          <div className="value">
            <div className="bar">
              <span style={{ "--bar-rating": data!.rates.value } as CSSProperties} />
            </div>
            <span>{ratingFormatter(data!.rates.value)}</span>
          </div>
        </div>
      </div>

    </div>);

});

export default Reviews;
