import React, {useMemo} from "react";
import {FormatNumberOptions, useIntl} from "react-intl";
import {AnyPrice, classNames, DiscountedPrice, isDiscountedPrice, isPriceWithExtras} from "@ct-react/core";
import {DataProps} from "../../tools/components";
import "./price.scss"

type PriceProps = DataProps<AnyPrice> & { total?: boolean };

const Price = (
  {
    className,
    total = false,
    data
  }: PriceProps) => {

  const intl = useIntl();

  const discounted = useMemo(() => isDiscountedPrice(data) && (!total || !isPriceWithExtras(data)), [ data ]);
  const formatCurrency = useMemo<FormatNumberOptions>(() => ({ style: "currency", currency: data.currency }), [data ]);

  const wrapperClasses = classNames("r-price", className);

  return (
    <span className={classNames(wrapperClasses, { discounted })}>
      {discounted &&
        <span className="origin">
          {intl.formatNumber((data as DiscountedPrice).original, formatCurrency)}
        </span>
      }
      {intl.formatNumber(total && isPriceWithExtras(data) ? data.fullAmount : data.amount, formatCurrency)}
    </span>);

}

export default Price;
