import React, {useState} from "react";
import { classNames } from "@ct-react/core";
import {Image, Ratio} from "../../models/images";
import "./ratio-images.scss";

type RatioImageProps = {
  className?: string;
  ratio?: Ratio;
  images: Image;
  alt: string;
}

const RatioImage = (props: RatioImageProps) => {

  const [ ratio ] = useState(props.ratio || Ratio.THREE_TWO);

  return (
    <picture className={classNames("r-img", ratio, props.className)}>
      <source srcSet={props.images.medium} media="(max-width: 200px)" />
      <source srcSet={props.images.large} media="(max-width: 800px)" />
      <source srcSet={props.images.extraLarge} media="(max-width: 1200px)" />
      <img src={props.images.large} alt={props.alt} />
    </picture>
  );

};

export default RatioImage;
