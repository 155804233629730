import {
  FilterSearchCriteria,
  FilterSearchFeaturesCriteria,
  FlexBookingConfig,
  isFlexBookingConfig,
  MainSearchCriteria,
  RangeBookingConfig,
  SearchContext,
  SearchQuery
} from "../../models/search";

const toBoolean = (params: URLSearchParams, name: string): boolean | undefined =>
  params.has(name) && Boolean(params.get(name)) ? JSON.parse(params.get(name)!) : undefined;

const parseFeaturesSearchQueryParams = (params: URLSearchParams): FilterSearchFeaturesCriteria => {
  const wifi = toBoolean(params, "feat_wifi");
  const tv = toBoolean(params, "feat_tv");
  const washingMachine = toBoolean(params, "feat_washing_machine");
  const dishwasher = toBoolean(params, "feat_dishwasher");
  const fireplace = toBoolean(params, "feat_fireplace");
  const parking = toBoolean(params, "feat_parking");
  const balcony = toBoolean(params, "feat_balcony");
  const terrace = toBoolean(params, "feat_terrace");
  const pool = toBoolean(params, "feat_pool");
  const sauna = toBoolean(params, "feat_sauna");
  const smoker = toBoolean(params, "feat_smoker");
  const pet = toBoolean(params, "feat_pet");
  const disabledPerson = toBoolean(params, "feat_disabled_person");
  return {
    ...typeof wifi === "boolean" && { wifi },
    ...typeof tv === "boolean" && { tv },
    ...typeof washingMachine === "boolean" && { washingMachine },
    ...typeof dishwasher === "boolean" && { dishwasher },
    ...typeof fireplace === "boolean" && { fireplace },
    ...typeof parking === "boolean" && { parking },
    ...typeof balcony === "boolean" && { balcony },
    ...typeof terrace === "boolean" && { terrace },
    ...typeof pool === "boolean" && { pool },
    ...typeof sauna === "boolean" && { sauna },
    ...typeof smoker === "boolean" && { smoker },
    ...typeof pet === "boolean" && { pet },
    ...typeof disabledPerson === "boolean" && { disabledPerson }
  };
}

export const parseSearchQueryParams = (params: URLSearchParams): SearchQuery => {
  const period = params.get("period_type");
  const periodRangeFrom = params.get("period_range_checkin");
  const periodRangeTo = params.get("period_range_checkout");
  const periodRangeDayMargin = params.get("period_range_margin");
  const periodFlexDuration = params.get("period_flex_duration")?.toUpperCase();
  const periodFlexMonths = params.getAll("period_flex_month");
  const guests = params.get("guests");
  const objectTypes = params.getAll("object_type");
  const rooms = params.get("rooms");
  const bedrooms = params.get("bedrooms");
  const bathrooms = params.get("bathrooms");
  const features = parseFeaturesSearchQueryParams(params);
  const name = params.get("name");
  return {
    ...(!!period) && {
      ...(period === "range") && {
        period: {
          checkIn: periodRangeFrom,
          checkOut: periodRangeTo,
          ...(!!periodRangeDayMargin) && { dayMargin: +periodRangeDayMargin }
        } as RangeBookingConfig
      },
      ...(period === "flex") && {
        period: {
          duration: periodFlexDuration,
          ...(!!periodFlexMonths && periodFlexMonths.length > 0) && { intoMonths: periodFlexMonths }
        } as FlexBookingConfig
      }
    },
    ...(!!guests) && { guests: +guests },
    ...(!!objectTypes && objectTypes.length > 0) && { objectTypes },
    ...(!!rooms) && { rooms: +rooms },
    ...(!!bedrooms) && { bedrooms: +bedrooms },
    ...(!!bathrooms) && { bathrooms: +bathrooms },
    ...(Object.keys(features).length > 0) && { features },
    ...(!!name) && { name }
  }
}

export const parseSearchParams = (params: URLSearchParams): SearchContext => {
  const afterCursor = params.get("page_after_cursor");
  const beforeCursor = params.get("page_before_cursor");
  const mapView = toBoolean(params, "map_view") ?? false;
  return {
    query: {
      ...parseSearchQueryParams(params),
    },
    pagination: {
      direction: !!beforeCursor ? "before" : "after",
      ...(!!beforeCursor) && { cursor: beforeCursor },
      ...(!!afterCursor) && { cursor: afterCursor }
    },
    viewConfig: { mapView }
  };
}

export const rebuildQuerySearchParamsFromMain = (currentParams: URLSearchParams, query: MainSearchCriteria) => {
  Array.from(currentParams.keys())
    .filter(pk => pk.match(/^period_/))
    .forEach(pk => currentParams.delete(pk));
  if (!!query.period) {
    if (!isFlexBookingConfig(query.period)) {
      currentParams.set("period_type", "range");
      currentParams.set("period_range_checkin", query.period.checkIn);
      currentParams.set("period_range_checkout", query.period.checkOut);
      !!query.period.dayMargin && currentParams.set("period_range_margin", query.period.dayMargin.toString());
    } else {
      currentParams.set("period_type", "flex");
      currentParams.set("period_flex_duration", query.period.duration.toLowerCase());
      !!query.period.intoMonths && query.period.intoMonths.map(m => currentParams.append("period_flex_month", m));
    }
  }
  if (!query.guests && currentParams.has("guests")) currentParams.delete("guests");
  if (!!query.guests) currentParams.set("guests", query.guests.toString());
}

const rebuildQueryFeaturesSearchParamsFromFilter = (currentParams: URLSearchParams, query?: FilterSearchFeaturesCriteria) => {
  Array.from(currentParams.keys())
    .filter(pk => pk.match(/^feat_/))
    .forEach(pk => currentParams.delete(pk));
  if (!query) return;
  if ("wifi" in query) currentParams.set("feat_wifi", query.wifi!.toString());
  if ("tv" in query) currentParams.set("feat_tv", query.tv!.toString());
  if ("washingMachine" in query) currentParams.set("feat_washing_machine", query.washingMachine!.toString());
  if ("dishwasher" in query) currentParams.set("feat_dishwasher", query.dishwasher!.toString());
  if ("fireplace" in query) currentParams.set("feat_fireplace", query.fireplace!.toString());
  if ("parking" in query) currentParams.set("feat_parking", query.parking!.toString());
  if ("balcony" in query) currentParams.set("feat_balcony", query.balcony!.toString());
  if ("terrace" in query) currentParams.set("feat_terrace", query.terrace!.toString());
  if ("pool" in query) currentParams.set("feat_pool", query.pool!.toString());
  if ("sauna" in query) currentParams.set("feat_sauna", query.sauna!.toString());
  if ("smoker" in query) currentParams.set("feat_smoker", query.smoker!.toString());
  if ("pet" in query) currentParams.set("feat_pet", query.pet!.toString());
  if ("disabledPerson" in query) currentParams.set("feat_disabled_person", query.disabledPerson!.toString());
}

export const rebuildQuerySearchParamsFromFilter = (currentParams: URLSearchParams, query: FilterSearchCriteria) => {
  if (currentParams.has("object_type")) currentParams.delete("object_type");
  if (!!query.objectTypes) query.objectTypes.map(t => currentParams.append("object_type", t));
  if (!query.rooms && currentParams.has("rooms")) currentParams.delete("rooms");
  if (!!query.rooms) currentParams.set("rooms", query.rooms.toString());
  if (!query.bedrooms && currentParams.has("bedrooms")) currentParams.delete("bedrooms");
  if (!!query.bedrooms) currentParams.set("bedrooms", query.bedrooms.toString());
  if (!query.bathrooms && currentParams.has("bathrooms")) currentParams.delete("bathrooms");
  if (!!query.bathrooms) currentParams.set("bathrooms", query.bathrooms.toString());
  rebuildQueryFeaturesSearchParamsFromFilter(currentParams, query.features);
  if (!query.name && currentParams.has("name")) currentParams.delete("name");
  if (!!query.name) currentParams.set("name", query.name);
}
