import React, {useEffect, useMemo, useState} from "react";
import { useIntl } from "react-intl";
import { gql, useMutation } from "@apollo/client";
import {IconCheck, IconLoader3, IconX} from "@tabler/icons-react";
import {classNames, isPeriodValid, Period, useIsMounted} from "@ct-react/core";
import {formTranslations, globalTranslations} from "../../i18n/sharable-defs";
import {isotize} from "../../tools/moments";
import { useFormValidator } from "../../hooks/form";
import {
  emailFormValidator,
  minLengthFormValidator,
  notEmptyFormValidator,
  phoneFormValidator
} from "../../models/form";
import { Button } from "../common/minimals";
import "./common.scss";

type AgencyFormProps = {
  agencyId: string;
  articleId?: string;
  bookingChoice?: Period;
}

const SEND_MAIL_TO_AGENCY = gql`
  mutation MailAgency($agencyId: ID!, $content: MailContentInput!) {
    mailAgency(agencyId: $agencyId, content: $content)
  }
`;

const msgMinChar = 20;

const AgencyForm = (
  {
    agencyId,
    articleId,
    bookingChoice: propsBookingChoice = {}
  }: AgencyFormProps) => {

  const intl = useIntl();
  const istMounted = useIsMounted();

  const formDef = useMemo(() => ({
    firstName: { validator: notEmptyFormValidator },
    lastName: { validator: notEmptyFormValidator },
    email: { validator: emailFormValidator },
    phone: { validator: phoneFormValidator },
    body: { validator: minLengthFormValidator(msgMinChar) },
    articleId
  }), []);

  // component states

  const [ withBooking, setWithBooking ] = useState<boolean>(isPeriodValid(propsBookingChoice));
  const [ formCallback, setFormCallback ] = useState<boolean | undefined>(undefined);
  const { formState, formData, formPurge, isValid, onInputChange, onInputBlur } = useFormValidator(formDef);

  useEffect(() => {
    if (!istMounted) return;
    setWithBooking(isPeriodValid(propsBookingChoice));
  }, [ propsBookingChoice ]);

  // data mutation

  const [ sendMail, { loading } ] = useMutation(SEND_MAIL_TO_AGENCY, {
    onCompleted: () => {
      formPurge();
      setFormCallback(true);
      setTimeout(() => setFormCallback(undefined), 5000)
    },
    onError: () => {
      setFormCallback(false);
      setTimeout(() => setFormCallback(undefined), 5000)
    }
  });

  // dom interactions

  const onSubmit = (e) => {
    e.preventDefault();
    if (!isValid) return;

    sendMail({
      variables: {
        agencyId,
        content: {
          ...formData,
          ...(withBooking) && {
            checkIn: isotize(propsBookingChoice!.start!),
            checkOut: isotize(propsBookingChoice!.end!)
          }
        }
      }
    }).then()
  };

  // rendering

  return (
    <form className="r-form" onSubmit={onSubmit}>

      <div className="rf-valid-group">
        <input className={classNames({ dirty: formState.firstName.dirty })}
               type="text"
               name="firstName"
               autoComplete="given-name"
               placeholder={intl.formatMessage(formTranslations.firstNamePlaceholder)}
               value={formState.firstName.value || ""}
               onChange={e => onInputChange(e.target.name, e.target.value)}
               onBlur={e => onInputBlur(e.target.name, e.target.value)}
               disabled={loading} />
        {formState.firstName.dirty &&
          <p className="rf-error">{intl.formatMessage(formTranslations.emptyError)}</p>
        }
      </div>

      <div className="rf-valid-group">
        <input className={classNames({ dirty: formState.lastName.dirty })}
               type="text"
               name="lastName"
               autoComplete="family-name"
               placeholder={intl.formatMessage(formTranslations.lastNamePlaceholder)}
               value={formState.lastName.value || ""}
               onChange={e => onInputChange(e.target.name, e.target.value)}
               onBlur={e => onInputBlur(e.target.name, e.target.value)}
               disabled={loading} />
        {formState.lastName.dirty &&
          <p className="rf-error">{intl.formatMessage(formTranslations.emptyError)}</p>
        }
      </div>

      <div className="rf-valid-group">
        <input className={classNames({ dirty: formState.email.dirty })}
               type="email"
               name="email"
               autoComplete="email"
               placeholder={intl.formatMessage(formTranslations.emailPlaceholder)}
               value={formState.email.value || ""}
               onChange={e => onInputChange(e.target.name, e.target.value)}
               onBlur={e => onInputBlur(e.target.name, e.target.value)}
               disabled={loading} />
        {formState.email.dirty &&
          <p className="rf-error">
            {(!formState.email.value || formState.email.value.length == 0)
              ? intl.formatMessage(formTranslations.emptyError)
              : intl.formatMessage(formTranslations.mailError)
            }
          </p>
        }
      </div>

      <div className="rf-valid-group">
        <input className={classNames({ dirty: formState.phone.dirty })}
               type="tel"
               name="phone"
               autoComplete="tel"
               placeholder={intl.formatMessage(formTranslations.phonePlaceholder)}
               value={formState.phone.value || ""}
               onChange={e => onInputChange(e.target.name, e.target.value)}
               onBlur={e => onInputBlur(e.target.name, e.target.value)}
               disabled={loading} />
        {formState.phone.dirty &&
          <p className="rf-error">
            {(!formState.phone.value || formState.phone.value.length == 0)
              ? intl.formatMessage(formTranslations.emptyError)
              : intl.formatMessage(formTranslations.phoneError)
            }
          </p>
        }
      </div>

      {isPeriodValid(propsBookingChoice) &&
        <div className="rf-line-group">
          <label className="rf-checkbox">
            <input type="checkbox"
                   id="withBooking"
                   checked={withBooking}
                   onChange={e => setWithBooking(e.target.checked)}
                   disabled={loading} />
            <span className="rf-mark" />
          </label>
          <label htmlFor="withBooking">{intl.formatMessage(formTranslations.sendWithBookingPeriod)}</label>
        </div>
      }

      <div className="rf-valid-group">
        <textarea className={classNames({ dirty: formState.body.dirty })}
                  name="body"
                  placeholder={intl.formatMessage(formTranslations.messagePlaceholder)}
                  value={formState.body.value || ""}
                  onChange={e => onInputChange(e.target.name, e.target.value)}
                  onBlur={e => onInputBlur(e.target.name, e.target.value)}
                  rows={5}
                  disabled={loading} />
        {formState.body.dirty &&
          <p className="rf-error">
            {(!formState.body.value || formState.body.value.length == 0)
              ? intl.formatMessage(formTranslations.emptyError)
              : intl.formatMessage(formTranslations.minError, { value: msgMinChar })
            }
          </p>
        }
      </div>

      <div className="rf-submit-group">
        <Button className={classNames("rf-submitter bolder loadable-keeping-size", { loading })}
                type="submit"
                disabled={!isValid}>
          <span className="text">{intl.formatMessage(globalTranslations.send)}</span>
          <div className="loader"><IconLoader3 /></div>
        </Button>
        {(typeof formCallback !== "undefined") &&
          <span className={classNames("callback", { fail: !formCallback })}>
            {formCallback
              ? <><IconCheck />{intl.formatMessage(formTranslations.sent)}</>
              : <><IconX />{intl.formatMessage(formTranslations.failed)}</>
            }
          </span>
        }
      </div>

    </form>);

};

export default AgencyForm;
