import React from "react";
import {useIntl} from "react-intl";
import Skeleton from "react-loading-skeleton";
import {
  IconDeviceTv,
  IconDeviceTvOff,
  IconDisabled,
  IconDisabledOff,
  IconFence,
  IconFenceOff,
  IconFlame,
  IconFlameOff,
  IconParking,
  IconParkingOff,
  IconPaw,
  IconPool,
  IconSmoking,
  IconSmokingNo,
  IconWashMachine,
  IconWifi,
  IconWifiOff
} from "@tabler/icons-react";
import {classNames} from "@ct-react/core";
import {featureTranslations} from "../../../i18n/sharable-defs";
import {SkeletonableProps} from "../../../tools/components";
import SaunaSvg from "../../../assets/componable-svgs/feat-sauna.svg";
import DishwasherSvg from "../../../assets/componable-svgs/feat-dishwasher.svg";
import BalconySvg from "../../../assets/componable-svgs/feat-balcony.svg";
import SmokeAlarmSvg from "../../../assets/componable-svgs/feat-smoke-alarm.svg";
import CarbonAlarmSvg from "../../../assets/componable-svgs/feat-carbon-alarm.svg";
import SmokeAlarmOffSvg from "../../../assets/componable-svgs/feat-smoke-alarm-off.svg";
import CarbonAlarmOffSvg from "../../../assets/componable-svgs/feat-carbon-alarm-off.svg";
import "./full-features.scss"

interface FeaturesData {
  isPetCompliant: boolean;
  isDisabledPersonCompliant: boolean;
  withBalcony: boolean;
  withTerrace: boolean;
  withParking: boolean;
  withWIFI: boolean;
  withTV: boolean;
  withLift: boolean;
  withDishwasher: boolean;
  withWashingMachine: boolean;
  withSauna: boolean;
  withPool: boolean;
  withFireplace: boolean;
  isSmokerCompliant: boolean;
  withSmokeAlarm: boolean;
  withCarbonAlarm: boolean;
}

const DisplayFullFeatures = (content: FeaturesData) => {

  const intl = useIntl();

  return(
    <>
      <span className={["a-feature", content!.isPetCompliant ? "yes" : "no" ].join(" ")}>
        <IconPaw />
        {intl.formatMessage(featureTranslations.pet)}
      </span>
      <span className={["a-feature", content!.isDisabledPersonCompliant ? "yes" : "no" ].join(" ")}>
        {content!.isDisabledPersonCompliant ? <IconDisabled /> : <IconDisabledOff />}
        {intl.formatMessage(featureTranslations.disabledPerson)}
      </span>
      <span className={["a-feature", content!.withBalcony ? "yes" : "no" ].join(" ")}>
        <BalconySvg className="custom" />
        {intl.formatMessage(featureTranslations.balcony)}
      </span>
      <span className={["a-feature", content!.withTerrace ? "yes" : "no" ].join(" ")}>
        {content!.withTerrace ? <IconFence /> : <IconFenceOff />}
        {intl.formatMessage(featureTranslations.terrace)}
      </span>
      <span className={["a-feature", content!.withParking ? "yes" : "no" ].join(" ")}>
        {content!.withParking ? <IconParking /> : <IconParkingOff />}
        {intl.formatMessage(featureTranslations.parking)}
      </span>
      <span className={["a-feature", content!.withWIFI ? "yes" : "no" ].join(" ")}>
        {content!.withWIFI ? <IconWifi /> : <IconWifiOff />}
        {intl.formatMessage(featureTranslations.wifi)}
      </span>
      <span className={["a-feature", content!.withTV ? "yes" : "no" ].join(" ")}>
        {content!.withTV ? <IconDeviceTv /> : <IconDeviceTvOff />}
        {intl.formatMessage(featureTranslations.tv)}
      </span>
      <span className={["a-feature", content!.withWashingMachine ? "yes" : "no" ].join(" ")}>
        <IconWashMachine />
        {intl.formatMessage(featureTranslations.washingMachine)}
      </span>
      <span className={["a-feature", content!.withDishwasher ? "yes" : "no" ].join(" ")}>
        <DishwasherSvg className="custom" />
        {intl.formatMessage(featureTranslations.dishwasher)}
      </span>
      <span className={["a-feature", content!.withPool ? "yes" : "no" ].join(" ")}>
        <IconPool />
        {intl.formatMessage(featureTranslations.pool)}
      </span>
      <span className={["a-feature", content!.withSauna ? "yes" : "no" ].join(" ")}>
        <SaunaSvg className="custom" />
        {intl.formatMessage(featureTranslations.sauna)}
        </span>
      <span className={["a-feature", content!.withFireplace ? "yes" : "no" ].join(" ")}>
        {content!.withFireplace ? <IconFlame /> : <IconFlameOff />}
        {intl.formatMessage(featureTranslations.fireplace)}
      </span>
      <span className={["a-feature", !content!.isSmokerCompliant ? "yes" : "no" ].join(" ")}>
        {content!.isSmokerCompliant ? <IconSmoking /> : <IconSmokingNo />}
        {intl.formatMessage(featureTranslations.smoker)}
      </span>
      <span className={["a-feature", content!.withSmokeAlarm ? "yes" : "no" ].join(" ")}>
        {content!.withSmokeAlarm ? <SmokeAlarmSvg className="custom" />: <SmokeAlarmOffSvg className="custom" />}
        {intl.formatMessage(featureTranslations.smokerAlarm)}
      </span>
      <span className={["a-feature", content!.withCarbonAlarm ? "yes" : "no" ].join(" ")}>
        {content!.withCarbonAlarm ? <CarbonAlarmSvg className="custom" /> : <CarbonAlarmOffSvg className="custom" />}
        {intl.formatMessage(featureTranslations.carbonAlarm)}
      </span>
    </>);

}

type FullFeaturesProps = SkeletonableProps<FeaturesData>;

const FullFeatures = (
  {
    loading,
    className,
    data
  }: FullFeaturesProps) => {

  const intl = useIntl();

  return (
    <div className={classNames("rla-features", className)}>
      <h2>
        {!loading
          ? intl.formatMessage({
            id: "booking-features-title",
            defaultMessage: "Ce que propose ce logement"
          })
          : <Skeleton inline={true} width="50%" />
        }
      </h2>
      {!loading
        ? <div className="features-list">
            <DisplayFullFeatures {...data!} />
          </div>
        : <Skeleton containerClassName="features-list" className="a-feature" count={15} inline={true} />
      }
    </div>);

}

export default FullFeatures;
