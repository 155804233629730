import React, { useMemo, useState } from "react";
import { defineMessages, useIntl } from "react-intl";
import { gql, useQuery } from "@apollo/client";
import Skeleton from "react-loading-skeleton";
import { IconAdjustmentsHorizontal } from "@tabler/icons-react";
import { classNames } from "@ct-react/core";
import { SkeletonableProps } from "../../tools/components";
import { FilterSearchCriteria, FilterSearchFeaturesCriteria } from "../../models/search";
import Modal, { InnerModal } from "../common/modal";
import { Button } from "../common/minimals";
import { FilterFeatures, FilterName, FilterObjectTypes, FilterRooms } from "./filter-content";
import "./filter-button.scss";

const OBJECT_TYPES_GQL_DATA = gql`
  query ObjectTypesList($agencyId: ID) {
    objectTypes: searchObjectTypes(agencyId: $agencyId)
  }
`;

const transDefs = defineMessages({
  objectsTitle: { id: "rls-search-filter-objects-title", defaultMessage: "Type de logement" },
  roomsTitle: { id: "rls-search-filter-rooms-title", defaultMessage: "Pièces" },
  roomsCategory: { id: "rls-search-filter-rooms-category", defaultMessage: "Ensemble" },
  bedroomsCategory: { id: "rls-search-filter-bedrooms-category", defaultMessage: "Chambres" },
  bathroomsCategory: { id: "rls-search-filter-bathrooms-category", defaultMessage: "Salles de bains" },
  featuresCategory: { id: "rls-search-filter-features-title", defaultMessage: "Caractéristiques" },
  nameTitle: { id: "rls-search-filter-name-title", defaultMessage: "Nom du logement" },
  filter: { id: "rls-search-filter-submit-button", defaultMessage: "Filtrer" },
  reset: { id: "rls-search-filter-reset-button", defaultMessage: "Tout effacer" }
});

type FilterButtonProps = SkeletonableProps<FilterSearchCriteria> & {
  agencyId?: string;
  countedResult?: number;
  onDataChange?: (criteria: FilterSearchCriteria) => void;
}

const FilterButton = (
  {
    className,
    loading = false,
    agencyId,
    countedResult,
    data: criteria = {},
    onDataChange = () => void 0
  }: FilterButtonProps) => {

  const intl = useIntl();

  // component states

  const [ isOpen, setIsOpen ] = useState<boolean>(false);

  const [ objectTypes, setObjectTypes ] = useState<string[] | undefined>(criteria?.objectTypes);
  const [ rooms, setRooms ] = useState<number | undefined>(criteria?.rooms);
  const [ bedrooms, setBedrooms ] = useState<number | undefined>(criteria?.bedrooms);
  const [ bathrooms, setBathrooms ] = useState<number | undefined>(criteria?.bathrooms);
  const [ features, setFeatures ] = useState<FilterSearchFeaturesCriteria | undefined>(criteria?.features);
  const [ name, setName ] = useState<string | undefined>(criteria?.name);

  const countFilter = useMemo(() => {
    const { objectTypes, rooms, bedrooms, bathrooms, features: correctableFeatures, name } = criteria;
    const features = { ...correctableFeatures, ...typeof correctableFeatures?.smoker === "boolean" &&  { smoker: !correctableFeatures.smoker } };
    return Object.values({ objectTypes, rooms, bedrooms, bathrooms, ...features, name }).filter(v => !!v).length;
  }, [ criteria ]);

  // data loading

  const { data } = useQuery<{ objectTypes: string[] }>(OBJECT_TYPES_GQL_DATA, { ssr: false, variables: { agencyId } });

  // dom interactions

  const onReset = () => {
    setObjectTypes(undefined);
    setRooms(undefined);
    setBedrooms(undefined);
    setBathrooms(undefined);
    setFeatures(undefined);
    setName(undefined);
  }

  const onApply = () => {
    setIsOpen(false);
    onDataChange({ objectTypes, rooms, bedrooms, bathrooms, features, name });
  }

  // rendering

  const wrapperClasses = classNames("rls-search-filter", className, { skeleton: loading });
  const buttonClasses = classNames("r-btn", "r-btn-search-filter", "inline-icon", "small", "tertiary", { badged: countFilter > 0 });

  if (loading)
    return (<Skeleton containerClassName={wrapperClasses} />);

  if (countFilter === 0 && countedResult === 0)
    return null;

  const submitter = (
    <>
      <Button type="button"
              className="link small"
              onClick={onReset}>{intl.formatMessage(transDefs.reset)}</Button>
      <Button type="button"
              className="secondary small"
              onClick={onApply}>{intl.formatMessage(transDefs.filter)}</Button>
    </>);

  return (
    <div className={wrapperClasses}>
      <Modal open={isOpen}
             onOpenChange={setIsOpen}>

        <InnerModal.Trigger as="button" className={buttonClasses}>
          <IconAdjustmentsHorizontal />
          {intl.formatMessage(transDefs.filter)}
          {countFilter > 0 && <span className="badge">{countFilter}</span> }
        </InnerModal.Trigger>

        <InnerModal.Content className="rls-filter-content"
                            footer={submitter}>

          <div className="rls-filter-item">
            <h2>{intl.formatMessage(transDefs.objectsTitle)}</h2>
            <FilterObjectTypes typesList={data?.objectTypes || []}
                               value={objectTypes}
                               onValueChange={setObjectTypes}/>
          </div>

          <div className="rls-filter-item">
            <h2>{intl.formatMessage(transDefs.roomsTitle)}</h2>
            <h3>{intl.formatMessage(transDefs.roomsCategory)}</h3>
            <FilterRooms value={rooms} onValueChange={setRooms}/>
            <h3>{intl.formatMessage(transDefs.bedroomsCategory)}</h3>
            <FilterRooms value={bedrooms} onValueChange={setBedrooms}/>
            <h3>{intl.formatMessage(transDefs.bathroomsCategory)}</h3>
            <FilterRooms value={bathrooms} onValueChange={setBathrooms}/>
          </div>

          <div className="rls-filter-item">
            <h2>{intl.formatMessage(transDefs.featuresCategory)}</h2>
            <FilterFeatures value={features} onValueChange={setFeatures}/>
          </div>

          <div className="rls-filter-item">
            <h2>{intl.formatMessage(transDefs.nameTitle)}</h2>
            <FilterName value={name} onValueChange={setName}/>
          </div>

        </InnerModal.Content>

      </Modal>
    </div>);
}

export default FilterButton;
