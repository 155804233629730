import React, { forwardRef, useMemo } from "react";
import { useIntl } from "react-intl";
import { classNames, Period } from "@ct-react/core";
import { LocaleLink, useLocaleContext } from "@ct-react/locale";
import { buildAgencyUrl } from "@shared/urls";
import { agencyTranslations } from "../../../i18n/sharable-defs";
import { SkeletonableProps } from "../../../tools/components";
import { useDisplayDesktop, useDisplayMobileOnly } from "../../../tools/breakpoints";
import { Agency as AgencyModel } from "../../../models/agency";
import Modal, { InnerModal } from "../../common/modal";
import { ClampText } from "../../common/minimals";
import AgencyContactBox from "../../cards/agency-contact-box";
import AgencyForm from "../../forms/agency";
import "./agency.scss";

interface AgencyData {
  agency: AgencyModel;
  articleId?: string;
  bookingChoice?: Period;
}

type AgencyProps = SkeletonableProps<AgencyData>;

const Agency = forwardRef<HTMLDivElement, AgencyProps>((
  {
    className,
    data
  }: AgencyProps, forwardedRef) => {

  const intl = useIntl();
  const { locale } = useLocaleContext();

  // rendering

  const isMobile = useDisplayMobileOnly();
  const withModalForm = !useDisplayDesktop();
  const wrapperClasses = classNames("rla-agency", className);
  const displayStateClasses = classNames("agency-wrapper", { "no-lead": !data?.agency.logo || !data?.agency.lead });

  const formRendering = useMemo(() => (
    <AgencyForm agencyId={data!.agency.id}
                articleId={data!.articleId}
                bookingChoice={data!.bookingChoice} />), [ locale, isMobile, data ]);

  const formWrapperRendering = useMemo(() => {
    if (!withModalForm)
      return (<div className="form-wrapper">{formRendering}</div>);
    return (
      <Modal>

        <InnerModal.Trigger as="button" className="r-btn bolder">
          {intl.formatMessage(agencyTranslations.contact)}
        </InnerModal.Trigger>

        <InnerModal.Content title={intl.formatMessage(agencyTranslations.contact)}>
          {formRendering}
        </InnerModal.Content>

      </Modal>);
  }, [locale, isMobile, withModalForm, formRendering]);

  return (
    <div className={wrapperClasses} ref={forwardedRef}>
      <div className="title">
        <h2>
          {intl.formatMessage({
            id: "booking-agency-title",
            defaultMessage: "Proposé par l'agence"
          })}
        </h2>
      </div>
      <div className={displayStateClasses}>
        {!!data!.agency.logo &&
          <div className="logo">
            <LocaleLink to={buildAgencyUrl(data!.agency)}>
              <img src={data!.agency.logo} alt={data!.agency.name} />
            </LocaleLink>
          </div>
          }
        {(!!data!.agency.lead && data!.agency.lead.value.length > 0) &&
          <div className="about">
            <h3>
              {intl.formatMessage(agencyTranslations.about)}
            </h3>
            <ClampText text={data!.agency.lead.value} />
          </div>
        }
        <AgencyContactBox data={data!.agency} />
        {formWrapperRendering}
      </div>
    </div>);

});

export default Agency;
