import {Locale} from "@ct-react/locale";
import French from "./fr-CH.json";
import German from "./de-CH.json";
import English from "./en-GB.json";

export const getIntlMessages = (locale: Locale) => {
  switch (locale.basename) {
    case "fr": return French;
    case "de": return German;
    case "en": return English;
    default: throw new Error(`missing translations for locale ${locale.code}`);
  }
};
