import React from "react";
import {useIntl} from "react-intl";
import Skeleton from "react-loading-skeleton";
import {classNames} from "@ct-react/core";
import {SkeletonableProps} from "../../../tools/components";
import {DescriptionScalar} from "../../../models/description";
import {LangWarning} from "../../common/info-box";
import "./costs.scss";

interface CostsData {
  cleaning?: DescriptionScalar,
  bed?: DescriptionScalar,
  kitchen?: DescriptionScalar,
  bath?: DescriptionScalar,
  parking?: DescriptionScalar,
  pet?: DescriptionScalar,
  benefits?: DescriptionScalar,
  tax?: DescriptionScalar,
  deposit?: DescriptionScalar,
  charges?: DescriptionScalar,
  cancellation?: DescriptionScalar,
}

const DisplayCosts = (content: CostsData) => {

  const intl = useIntl();

  return (
    <>
      {!!content.cleaning &&
        <div className="a-cost">
          <span>{intl.formatMessage({ id: "booking-costs-cleaning", defaultMessage: "Nettoyage"})}</span>
          <span>{content.cleaning.value}</span>
        </div>
      }
      {!!content.bed &&
        <div className="a-cost">
          <span>{intl.formatMessage({ id: "booking-costs-bed", defaultMessage: "Draps de lit"})}</span>
          <span>{content.bed.value}</span>
        </div>
      }
      {!!content.kitchen &&
        <div className="a-cost">
          <span>{intl.formatMessage({ id: "booking-costs-kitchen", defaultMessage: "Linges de cuisine"})}</span>
          <span>{content.kitchen.value}</span>
        </div>
      }
      {!!content.bath &&
        <div className="a-cost">
          <span>{intl.formatMessage({ id: "booking-costs-bath", defaultMessage: "Linges de bain"})}</span>
          <span>{content.bath.value}</span>
        </div>
      }
      {!!content.parking &&
        <div className="a-cost">
          <span>{intl.formatMessage({ id: "booking-costs-parking", defaultMessage: "Parking"})}</span>
          <span>{content.parking.value}</span>
        </div>
      }
      {!!content.pet &&
        <div className="a-cost">
          <span>{intl.formatMessage({ id: "booking-costs-pet", defaultMessage: "Animaux domestiques"})}</span>
          <span>{content.pet.value}</span>
        </div>
      }
      {!!content.deposit &&
        <div className="a-cost">
          <span>{intl.formatMessage({ id: "booking-costs-deposit", defaultMessage: "Caution"})}</span>
          <span>{content.deposit.value}</span>
        </div>
      }
      {!!content.tax &&
        <div className="a-cost">
          <span>{intl.formatMessage({ id: "booking-costs-tax", defaultMessage: "Taxes de séjour"})}</span>
          <span>{content.tax.value}</span>
        </div>
      }
      {!!content.cancellation &&
        <div className="a-cost">
          <span>{intl.formatMessage({ id: "booking-costs-cancellation", defaultMessage: "Assurance annulation"})}</span>
          <span>{content.cancellation.value}</span>
        </div>
      }
      {!!content.charges &&
        <div className="a-cost">
          <span>{intl.formatMessage({ id: "booking-costs-charges", defaultMessage: "Frais d'administration"})}</span>
          <span>{content.charges.value}</span>
        </div>
      }
    </>);
}

type CostsProps = SkeletonableProps<CostsData>;

const Costs = (
  {
    loading,
    className,
    data
  }: CostsProps) => {

  const intl = useIntl();

  return (
    <div className={classNames("rla-costs", className)}>
      <h2>
        {!loading
          ? intl.formatMessage({
            id: "booking-charges-title",
            defaultMessage: "Frais et prestations de ce logement"
          })
          : <Skeleton inline={true} width="50%"/>
        }
      </h2>
      {!loading
        ? <>
          {Object.values(data!).filter(c => !!c && typeof c === "object").some(c => !c.matchRequestedLang) &&
            <LangWarning msg={intl.formatMessage({
              id: "booking-mixed-costs-lang",
              defaultMessage: "Certaines informations ne sont pas disponibles dans votre langue."
            })}/>
          }
          <div className="costs-list">
            <DisplayCosts {...data!} />
          </div>
          {!!data?.benefits &&
            <div className="costs-additional">
              <span>{intl.formatMessage({ id: "booking-costs-benefits", defaultMessage: "Prestations complémentaires"})}</span>
              <div className="format-ih" dangerouslySetInnerHTML={{ __html: data.benefits.value }} />
            </div>
          }
          {Object.values(data!).every(c => !c) &&
            <LangWarning msg={intl.formatMessage({
              id: "booking-no-costs-info",
              defaultMessage: "Il n'y a aucune information pour ce logement."
            })} />
          }
        </>
        : <Skeleton containerClassName="costs-list" className="a-cost" count={10} inline={true}/>
      }
    </div>);

}

export default Costs;
