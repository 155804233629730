import React, {ReactNode} from "react";
import moment from "moment";
import {useIntl} from "react-intl";
import {IconLoader3} from "@tabler/icons-react";
import {classNames} from "@ct-react/core";
import {useNavigate} from "@ct-react/locale";
import {globalTranslations} from "../../i18n/sharable-defs";
import {formatPeriod} from "../../hooks/format";
import {CartItem as CartItemModel} from "../../models/cart";
import {Ratio} from "../../models/images";
import {Button} from "../common/minimals";
import RatioImage from "../common/ratio-image";
import CartPrice from "./cart-price";
import {CartItemCountdown} from "../../code-splitting/client-only-ui";
import LastMinuteWarning from "./last-minute-warning";
import "./cart-item.scss";

type CartItemProps = CartItemModel & {
  className?: string;
  nextStep?: ReactNode;
  actionRemoving?: boolean;
  onRemove?: (id: string) => void;
}

const CartItem = (
  {
    className,
    actionRemoving = false,
    onRemove = () => void 0,
    nextStep,
    ...props
  }: CartItemProps) => {

  const intl = useIntl();
  const navigate = useNavigate();

  const wrapperClasses = classNames("cart-item", className);
  const priceCollWrapperClasses = classNames("cart-item-price", { "with-action": !!nextStep });

  return (
    <article className={wrapperClasses}>

      <h1>{props.title}</h1>
      <span className="desc">{props.description}</span>

      <div className="cart-item-details">

        <div className="cart-item-resume">
          <RatioImage images={props.image} ratio={Ratio.SIXTEEN_NINE} alt={props.title} />
          <div className="period">
              <span className="label">
                {intl.formatMessage({
                  id: "cart-item-book-label",
                  defaultMessage: "Dates réservées"
                })}
              </span>
            <span className="value">{formatPeriod(intl, props.period, true)}</span>
          </div>
          <div className="actions">
            <Button type="button"
                    className={classNames("secondary bolder loadable-keeping-size", { loading: actionRemoving })}
                    onClick={() => onRemove(props.id)}>
              <span className="text">{intl.formatMessage(globalTranslations.cancel)}</span>
              <div className="loader"><IconLoader3 /></div>
            </Button>
          </div>
          <div className="cart-item-lifetime">
            <CartItemCountdown expirationDate={moment(props.expirationDate)}
                               onRefresh={() => navigate(0)} />
          </div>
        </div>

        <div className={priceCollWrapperClasses}>
          <CartPrice {...props.price} />
          {!!nextStep &&
            <>
              <LastMinuteWarning price={props.price} />
              {nextStep}
            </>
          }
        </div>

      </div>

    </article>);

}

export default CartItem;
