import React, {CSSProperties, ElementType, HTMLAttributes, ReactNode, useCallback, useEffect, useState} from "react";
import {IconChevronLeft, IconChevronRight} from "@tabler/icons-react";
import useEmblaCarousel, {EmblaCarouselType} from "embla-carousel-react";
import { classNames } from "@ct-react/core";
import "./wrapper.scss";

type AnyWrapperProps = HTMLAttributes<HTMLElement> & {
  as?: ElementType;
  children?: ReactNode;
}

type BackgroundedWrapperProps = AnyWrapperProps & {
  type?: "gradient" | "boxed"
  ratio: number;
  imagePath: string;
}

type SliderWrapperProps = AnyWrapperProps & {
  wrapperClassName?: string;
  title?: string;
  withNav?: boolean;
}

const InternalWrapper = ({ as: Tag = "div", className, children, ...attributes }: AnyWrapperProps) =>
  <Tag className={classNames("rlw", className)}
       {...attributes}>
    {!!children && children}
  </Tag>;

const WrapperCentered = ({ className, ...props }: AnyWrapperProps) =>
  <InternalWrapper className={classNames("rlw-centered", className)}
                   {...props} />;

const WrapperBackgrounded = ({ className, children, ratio, imagePath, style = {},type = "gradient", ...props }: BackgroundedWrapperProps) =>
  <InternalWrapper className={classNames("rlw-backgrounded", type, className)}
                   style={{ ...style, "--ratio-bg": `${ratio}%`, "--image-bg": `url("${imagePath}")` } as CSSProperties}
                   {...props}>
    <div className="rlw-backgrounded-wrapper">{children}</div>
    <div className="rlw-backgrounded-img" />
  </InternalWrapper>;

const WrapperSlideable = ({ className, children, wrapperClassName, title, withNav = false, ...props }: SliderWrapperProps) => {

  const [ previousable, setPreviousable ] = useState<boolean>(false);
  const [ nextable, setNextable ] = useState<boolean>(false);
  const [ emblaRef, emblaApi ] = useEmblaCarousel({
    align: "start",
    skipSnaps: true,
    breakpoints: {
      "(min-width: 768px)": { slidesToScroll: 2 },
      "(min-width: 1200px)": { slidesToScroll: 3 },
      "(min-width: 1400px)": { slidesToScroll: 4 }
    }
  });

  const onSelect = useCallback((emblaApi: EmblaCarouselType) => {
    setPreviousable(emblaApi.canScrollPrev());
    setNextable(emblaApi.canScrollNext());
  }, []);

  useEffect(() => {
    if (!emblaApi) return;
    onSelect(emblaApi);
    emblaApi.on("reInit", onSelect);
    emblaApi.on("select", onSelect);
  }, [ emblaApi, onSelect ]);

  const scroll = useCallback((direction: -1 | 1) => {
    !!emblaApi && (direction < 0 ? emblaApi.scrollPrev() : emblaApi.scrollNext());
  }, [ emblaApi ]);

  return (
    <InternalWrapper className={classNames("rlw-shifted-slider", className)} {...props}>
      <WrapperCentered className="slider-head">
        {!!title && <WrapperInnerTitle>{title}</WrapperInnerTitle>}
        {withNav &&
          <div className="slider-nav">
            <button type="button" onClick={() => scroll(-1)} disabled={!previousable}><IconChevronLeft /></button>
            <button type="button" onClick={() => scroll(1)} disabled={!nextable}><IconChevronRight /></button>
          </div>
        }
      </WrapperCentered>
      <div ref={emblaRef} className="slider-content">
        <div className={classNames("slider-wrapper", wrapperClassName)}>{children}</div>
      </div>
    </InternalWrapper>);

}

const WrapperInnerTitle = ({ as = "h2", className, ...props }: AnyWrapperProps) =>
  <InternalWrapper as={as}
                   className={classNames("rlw-title", className) }
                   {...props} />;

namespace Wrapper {
  export const Centered = WrapperCentered;
  export const Backgrounded = WrapperBackgrounded;
  export const Slideable = WrapperSlideable;
  export const InnerTitle = WrapperInnerTitle;
}

export default Wrapper;
