import React, {useEffect, useState} from "react";
import {defineMessages, useIntl} from "react-intl";
import {IconChevronLeft, IconChevronRight, IconList, IconMap} from "@tabler/icons-react";
import {classNames, useIsMounted} from "@ct-react/core";
import {Button} from "../common/minimals";
import "./view-switcher.scss";

type ViewSwitcherProps = {
  forMobile?: boolean;
  mapView: boolean;
  onChange?: (mapView: boolean) => void;
}

const translations = defineMessages({
  map: { id: "search-view-switcher-map", defaultMessage: "Afficher la carte" },
  list: { id: "search-view-switcher-list", defaultMessage: "Afficher la liste" }
});

const ViewSwitcher = (
  {
    forMobile = false,
    mapView = false,
    onChange = () => void 0
  }: ViewSwitcherProps) => {

  const intl = useIntl();
  const isMounted = useIsMounted();

  // component states

  const [ isMapView, setIsMapView ] = useState(mapView);

  useEffect(() => {
    if (!isMounted) return;
    onChange(isMapView);
  }, [ isMapView ]);

  // rendering

  const classes = classNames("rls-view-switcher", {
    "rls-view-switcher": true,
    small: true,
    "rounded-icon": true,
    secondary: forMobile,
    light: !forMobile,
    mobile: forMobile,
    desktop: !forMobile
  });

  return (
    <>
      {
        // @ts-ignore
        <Button type="button"
                className={classes}
                onClick={() => setIsMapView(!isMapView)}>
          { forMobile
            ? (isMapView
              ? <>
                {intl.formatMessage(translations.list)}
                <IconList />
              </>
              : <>
                {intl.formatMessage(translations.map)}
                <IconMap />
              </>)
            : (isMapView
              ? <>
                <IconChevronRight />
                {intl.formatMessage(translations.list)}
              </>
              : <IconChevronLeft />)
          }
        </Button>
      }
    </>);

}

export default ViewSwitcher;
