import {Price} from "@ct-react/core";
import {DescriptionScalar} from "./description";
import {Image} from "./images";

interface IOptionDef {
  id: string;
  title: DescriptionScalar;
  description?: DescriptionScalar;
  images: Image[];
  forceSelection: boolean;
}

interface IOptionCustomDef {
  criteria: { quantity: number[] },
  grid: BenefitGridItem[]
}

export type BenefitGridItem = {
  quantity: number;
  price: Price
}

export type BenefitOptionDef = IOptionDef & IOptionCustomDef;

export type AnyOptionDef = BenefitOptionDef;

export const isCustomOptionDef = (value: AnyOptionDef): value is BenefitOptionDef =>
  "criteria" in value && "grid" in value;

