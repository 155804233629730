export type Image = {
  isMain: boolean;
  medium: string;
  large: string;
  extraLarge: string;
  original: string;
};

export enum Ratio {
  FOUR_THREE = "ratio-4-3",
  THREE_TWO = "ratio-3-2",
  SIXTEEN_NINE = "ratio-16-9",
  NINE_SIXTEEN = "ratio-9-16",
  FULL_CARD = "ratio-card",
  SQUARE = "ratio-square"
}
