import React, {useCallback, useState} from "react";
import Wrapper from "../../components/layout/wrapper";
import CheckoutStepper from "../../components/cart/checkout-stepper";
import {Outlet} from "react-router";
import {useVisitor} from "@ct-react/visitor";
import SeoHelmet from "../../components/seo-helmet";
import {defineMessages, useIntl} from "react-intl";

export type CartContext = {
  readonly sessionId?: string;
  readonly userId?: string;
  readonly index: number;
  readonly changeIndex: (val: number) => void;
}

export const cartStepTransDefs = defineMessages({
  cart: { id: "cart-stepper-cart-label", defaultMessage: "Panier" },
  info: { id: "cart-stepper-info-label", defaultMessage: "Vos informations" },
  payment: { id: "cart-stepper-payment-label", defaultMessage: "Paiement" }
});

const CartOutlet = () => {

  const intl = useIntl();
  const { sessionId, userId } = useVisitor();
  const [ index, setIndex ] = useState<number>(0);
  const changeIndex = useCallback((val: number) => setIndex(val), []);

  return(
    <div className="rl-cart">
      <SeoHelmet title={intl.formatMessage(cartStepTransDefs.cart)} />
      <Wrapper.Centered className="booking-process">
        <CheckoutStepper activeIndex={index} />
      </Wrapper.Centered>
      <Wrapper.Centered as="main">
        <Outlet context={{ sessionId, userId, index, changeIndex }} />
      </Wrapper.Centered>
    </div>);
}

export default CartOutlet;
