import {gql} from "@apollo/client";

export const FULL_LOCATION_FIELDS = gql`
  fragment FullLocationFields on Location {
    street streetNumber streetComplement zip city country
  }
`;

export const COST_DESCRIPTIONS_FIELDS = gql`
  fragment CostDescriptionsFields on RentalAccommodationCostDescriptions {
    cleaning { value matchRequestedLang }
    bed { value matchRequestedLang }
    kitchen { value matchRequestedLang }
    bath { value matchRequestedLang }
    parking { value matchRequestedLang }
    pet { value matchRequestedLang }
    benefits { value matchRequestedLang }
    tax { value matchRequestedLang }
    deposit { value matchRequestedLang }
    charges { value matchRequestedLang }
    cancellation { value matchRequestedLang }
  }
`;

export const IMAGE_FIELDS = gql`
  fragment ImageFields on Image {
    isMain medium large extraLarge original
  }
`;

export const MAIN_FEATURES_FIELDS = gql`
  fragment MainFeaturesFields on RentalAccommodationFeatures {
    rooms maxGuests bedrooms
  }
`;

export const FULL_AGENCY_FIELDS = gql`
  ${FULL_LOCATION_FIELDS}
  fragment FullAgencyFields on Agency {
    id
    slug
    name
    nameComplement
    location { ...FullLocationFields }
    phone
    fax
    email
    website
    logo
    lead { value }
  }
`;

export const CMS_AGENCY_FIELDS = gql`
  ${FULL_LOCATION_FIELDS}
  fragment CmsAgencyFields on AgencyCmsPage {
    id
    slug
    name
    nameComplement
    location { ...FullLocationFields }
    phone
    fax
    email
    website
    logo
    lead { matchRequestedLang value }
    htmlContent { matchRequestedLang value }
    coordinates
  }
`;

export const DISCOUNTS_FIELDS = gql`
  fragment DiscountsFields on DiscountDefinition {
    definitionType
    period
    type
    fixedValue { amount currency }
    percentageValue
  }`;

export const SUMMARY_FIELDS = gql`
  ${IMAGE_FIELDS}
  ${MAIN_FEATURES_FIELDS}
  ${DISCOUNTS_FIELDS}
  fragment SummaryFields on ArticleSummary {
    id
    slug
    title { value }
    images { ...ImageFields }
    features { ...MainFeaturesFields }
    discounts { ...DiscountsFields }
    agency { id slug }
  }
`;

export const DETAILED_FEATURES_FIELDS = gql`
  fragment DetailedFeaturesFields on RentalAccommodationFeatures {
    isPetCompliant
    isDisabledPersonCompliant
    withBalcony
    withTerrace
    withParking
    withWIFI
    withTV
    withLift
    withDishwasher
    withWashingMachine
    withSauna
    withPool
    withFireplace
    isSmokerCompliant
    withSmokeAlarm
    withCarbonAlarm
  }
`;

export const REVIEW_RATINGS_FIELDS = gql`
  fragment ReviewRatingsFields on DetailedReviewRates {
    welcome
    reservation
    overallComfort
    bedsComfort
    cleanliness
    accuracy
    location
    satisfaction
    value
  }
`;

export const PERIODED_SEARCH_BOOKING_SUGGESTIONS_FIELDS = gql`
  fragment BookingSuggestionFields on RentalAccommodationBookingOption {
    checkIn
    checkOut
    priceOnDemand
    price { currency original amount }
    extras { amount }
  }
`;

export const CART_PRICE_FIELDS = gql`
  fragment CartPriceFields on CartPrice {
    currency
    amount
    discount
    downPayment
  }
`;

export const CART_ITEM_PRICE_FIELDS = gql`
  fragment CartItemPriceFields on CartItemPrice {
    currency
    original
    discount
    optionDetails { optionType amount }
    amount
    downPayment
  }
`;

export const CART_ITEM_FIELDS = gql`
  ${IMAGE_FIELDS}
  ${CART_ITEM_PRICE_FIELDS}
  fragment CartItemFields on RentalAccommodationCartItem {
    id
    title
    description
    image { ...ImageFields }
    price { ...CartItemPriceFields }
    checkIn
    checkOut
    expirationDate
  }
`;
