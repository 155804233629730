import React, {useMemo, useState} from "react";
import {useIntl} from "react-intl";
import {IconCheck, IconLoader3, IconX} from "@tabler/icons-react";
import {classNames} from "@ct-react/core";
import {formTranslations, globalTranslations} from "../../i18n/sharable-defs";
import {UpdateUser, User} from "../../../shared/models/user";
import {useFormValidator} from "../../hooks/form";
import {useCountriesList} from "../../hooks/countries";
import {notEmptyFormValidator, phoneFormValidator} from "../../models/form";
import {Button} from "../common/minimals";
import "./common.scss";
import "./user-profile.scss";

type UserProfileFormProps = {
  user?: User;
  update: (val: UpdateUser) => Promise<User>;
}

const UserProfileForm = ({ user, update }: UserProfileFormProps) => {

  const intl = useIntl();
  const countries = useCountriesList();

  const formDef = useMemo(() => ({
    email: user?.email,
    username: user?.username,
    firstName: { defaultValue: user?.firstName, validator: notEmptyFormValidator },
    lastName: { defaultValue: user?.lastName, validator: notEmptyFormValidator },
    phone: { defaultValue: user?.phone, validator: phoneFormValidator },
    street: { defaultValue: user?.location?.street, validator: notEmptyFormValidator },
    streetNumber: user?.location?.streetNumber,
    streetComplement: user?.location?.streetComplement,
    zip: { defaultValue: user?.location?.zip, validator: notEmptyFormValidator },
    city: { defaultValue: user?.location?.city, validator: notEmptyFormValidator },
    country: { defaultValue: user?.location?.country, validator: notEmptyFormValidator },
  }), []);

  const [ onSaving, setOnSaving ] = useState<boolean>(false);
  const [ formCallback, setFormCallback ] = useState<boolean | undefined>(undefined);
  const { formState, formData, isValid, onInputChange, onInputBlur } = useFormValidator(formDef, true);

  // dom interactions

  const onSubmit = (e) => {
    e.preventDefault();
    if (!isValid) return;
    const { email, username, firstName, lastName, phone, ...location } = formData;
    setOnSaving(true);
    update({ firstName, lastName, phone, location } as UpdateUser)
      .then(() => {
        setFormCallback(true);
        setTimeout(() => setFormCallback(undefined), 5000);
      })
      .catch(() => {
        setFormCallback(false);
        setTimeout(() => setFormCallback(undefined), 5000);
      })
      .finally(() => setOnSaving(false));
  }

  // rendering

  return (
    <form onSubmit={onSubmit}>

      <div className="profile-form">
        <div className="r-form user-part">

          <div className="rf-valid-group">
            <input type="text"
                   name="username"
                   autoComplete="username"
                   value={formState.username.value || ""}
                   disabled readOnly />
          </div>

          <div className="rf-valid-group">
            <input type="email"
                   name="email"
                   autoComplete="email"
                   value={formState.email.value || ""}
                   disabled readOnly />
          </div>

          <div className="rf-valid-group">
            <input className={classNames({ dirty: formState.firstName.dirty })}
                   type="text"
                   name="firstName"
                   autoComplete="given-name"
                   placeholder={intl.formatMessage(formTranslations.firstNamePlaceholder)}
                   value={formState.firstName.value || ""}
                   onChange={e => onInputChange(e.target.name, e.target.value)}
                   onBlur={e => onInputBlur(e.target.name, e.target.value)} />
            {formState.firstName.dirty &&
              <p className="rf-error">{intl.formatMessage(formTranslations.emptyError)}</p>
            }
          </div>

          <div className="rf-valid-group">
            <input className={classNames({ dirty: formState.lastName.dirty })}
                   type="text"
                   name="lastName"
                   autoComplete="family-name"
                   placeholder={intl.formatMessage(formTranslations.lastNamePlaceholder)}
                   value={formState.lastName.value || ""}
                   onChange={e => onInputChange(e.target.name, e.target.value)}
                   onBlur={e => onInputBlur(e.target.name, e.target.value)} />
            {formState.lastName.dirty &&
              <p className="rf-error">{intl.formatMessage(formTranslations.emptyError)}</p>
            }
          </div>

          <div className="rf-valid-group">
            <input className={classNames({ dirty: formState.phone.dirty })}
                   type="tel"
                   name="phone"
                   autoComplete="tel"
                   placeholder={intl.formatMessage(formTranslations.phonePlaceholder)}
                   value={formState.phone.value || ""}
                   onChange={e => onInputChange(e.target.name, e.target.value)}
                   onBlur={e => onInputBlur(e.target.name, e.target.value)} />
            {formState.phone.dirty &&
              <p className="rf-error">
                {(!formState.phone.value || formState.phone.value.length == 0)
                  ? intl.formatMessage(formTranslations.emptyError)
                  : intl.formatMessage(formTranslations.phoneError)
                }
              </p>
            }
          </div>

        </div>
        <div className="r-form address-part">

          <div className="rf-valid-group">
            <div className="rf-line-group">
              <input className={classNames({ dirty: formState.street.dirty })}
                     type="text"
                     name="street"
                     placeholder={intl.formatMessage(formTranslations.streetPlaceholder)}
                     value={formState.street.value || ""}
                     onChange={e => onInputChange(e.target.name, e.target.value)}
                     onBlur={e => onInputBlur(e.target.name, e.target.value)} />
              <input className="street-min"
                     type="text"
                     name="streetNumber"
                     placeholder={intl.formatMessage(formTranslations.streetNumberPlaceholder)}
                     value={formState.streetNumber.value || ""}
                     onChange={e => onInputChange(e.target.name, e.target.value)}
                     onBlur={e => onInputBlur(e.target.name, e.target.value)} />
            </div>
            {formState.street.dirty &&
              <p className="rf-error">{intl.formatMessage(formTranslations.emptyError)}</p>
            }
          </div>

          <div className="rf-valid-group">
            <input type="text"
                   name="streetComplement"
                   placeholder={intl.formatMessage(formTranslations.streetComplementPlaceholder)}
                   value={formState.streetComplement.value || ""}
                   onChange={e => onInputChange(e.target.name, e.target.value)}
                   onBlur={e => onInputBlur(e.target.name, e.target.value)} />
          </div>

          <div className="rf-valid-group">
            <div className="rf-line-group">
              <input className={classNames("zip-min", { dirty: formState.zip.dirty })}
                     type="text"
                     name="zip"
                     autoComplete="postal-code"
                     placeholder={intl.formatMessage(formTranslations.zipPlaceholder)}
                     value={formState.zip.value || ""}
                     onChange={e => onInputChange(e.target.name, e.target.value)}
                     onBlur={e => onInputBlur(e.target.name, e.target.value)} />
              <input className={classNames({ dirty: formState.city.dirty })}
                     type="text"
                     name="city"
                     placeholder={intl.formatMessage(formTranslations.cityPlaceholder)}
                     value={formState.city.value || ""}
                     onChange={e => onInputChange(e.target.name, e.target.value)}
                     onBlur={e => onInputBlur(e.target.name, e.target.value)} />
            </div>
            {(formState.zip.dirty || formState.city.dirty) &&
              <p className="rf-error">{intl.formatMessage(formTranslations.emptyError)}</p>
            }
          </div>

          <div className="rf-valid-group">
            <select className={classNames({ dirty: formState.country.dirty })}
                    name="country"
                    value={formState.country.value || ""}
                    onChange={e => onInputChange(e.target.name, e.target.value)}
                    onBlur={e => onInputBlur(e.target.name, e.target.value)}
                    required>
              <option value="">{intl.formatMessage(formTranslations.countryPlaceholder)}</option>
              {Object.entries(countries).map(([ val, label ], i) =>
                <option key={i} value={val}>{label}</option>
              )}
            </select>
            {formState.country.dirty &&
              <p className="rf-error">{intl.formatMessage(formTranslations.emptyError)}</p>
            }
          </div>

        </div>
      </div>

      <div className="rf-submit-group">
        <Button className={classNames("rf-submitter bolder loadable-keeping-size", { loading: onSaving })}
                type="submit"
                disabled={!isValid}>
          <span className="text">{intl.formatMessage(globalTranslations.save)}</span>
          <div className="loader"><IconLoader3 /></div>
        </Button>
        {(typeof formCallback !== "undefined") &&
          <span className={classNames("callback", { fail: !formCallback })}>
            {formCallback
              ? <><IconCheck />{intl.formatMessage(formTranslations.saved)}</>
              : <><IconX />{intl.formatMessage(formTranslations.failedSave)}</>
            }
          </span>
        }
      </div>

    </form>);

}

export default UserProfileForm;
