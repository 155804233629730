import React, {FormHTMLAttributes, forwardRef, useEffect, useMemo} from "react";
import {useIntl} from "react-intl";
import {classNames, useIsMounted} from "@ct-react/core";
import {formTranslations} from "../../i18n/sharable-defs";
import {useFormValidator} from "../../hooks/form";
import {useCountriesList} from "../../hooks/countries";
import {CartCustomer} from "../../models/cart";
import {emailFormValidator, notEmptyFormValidator, phoneFormValidator} from "../../models/form";
import "./common.scss";

type PaymentInformationFormProps = {
  className?: string,
  data?: CartCustomer,
  onChange?: (data: CartCustomer, isValid: boolean) => void;
}

const PaymentInformationForm = (
  {
    className,
    data: propsData,
    onChange = () => void 0,
  }: PaymentInformationFormProps, forwardedRef) => {

  const intl = useIntl();
  const isMounted = useIsMounted();
  const countries = useCountriesList();

  const formDef = useMemo(() => ({
    firstName: { defaultValue: propsData?.firstName, validator: notEmptyFormValidator },
    lastName: { defaultValue: propsData?.lastName, validator: notEmptyFormValidator },
    email: { defaultValue: propsData?.email, validator: emailFormValidator },
    phone: { defaultValue: propsData?.phone, validator: phoneFormValidator },
    street: { defaultValue: propsData?.address.street, validator: notEmptyFormValidator },
    streetNumber: propsData?.address.streetNumber,
    streetComplement: propsData?.address.streetComplement,
    zip: { defaultValue: propsData?.address.zip, validator: notEmptyFormValidator },
    city: { defaultValue: propsData?.address.city, validator: notEmptyFormValidator },
    country: { defaultValue: propsData?.address.country, validator: notEmptyFormValidator }
  }), []);

  const { formData, formState, isValid, onInputChange, onInputBlur, formPurge } = useFormValidator(formDef);

  useEffect(() => {
    if (!isMounted) return;
    const { firstName, lastName, email, phone, address } = propsData || {};
    formPurge({ firstName, lastName, email, phone, ...address });
  }, [ propsData ]);

  useEffect(() => {
    const { firstName, lastName, email, phone, ...address } = formData;
    onChange({ firstName, lastName, email, phone, address } as CartCustomer, isValid);
  }, [ formData, isValid ]);

  // rendering

  const wrapperClasses = classNames( "payment-infos", className);

  return (
    <form ref={forwardedRef}
          className={wrapperClasses}>

      <div className="r-form">

        <div className="rf-valid-group">
          <input className={classNames({ dirty: formState.firstName.dirty })}
                 type="text"
                 name="firstName"
                 autoComplete="given-name"
                 placeholder={intl.formatMessage(formTranslations.firstNamePlaceholder)}
                 value={formState.firstName.value || ""}
                 onChange={e => onInputChange(e.target.name, e.target.value)}
                 onBlur={e => onInputBlur(e.target.name, e.target.value)} />
          {formState.firstName.dirty &&
            <p className="rf-error">{intl.formatMessage(formTranslations.emptyError)}</p>
          }
        </div>

        <div className="rf-valid-group">
          <input className={classNames({ dirty: formState.lastName.dirty })}
                 type="text"
                 name="lastName"
                 autoComplete="family-name"
                 placeholder={intl.formatMessage(formTranslations.lastNamePlaceholder)}
                 value={formState.lastName.value || ""}
                 onChange={e => onInputChange(e.target.name, e.target.value)}
                 onBlur={e => onInputBlur(e.target.name, e.target.value)} />
          {formState.lastName.dirty &&
            <p className="rf-error">{intl.formatMessage(formTranslations.emptyError)}</p>
          }
        </div>

        <div className="rf-valid-group">
          <input className={classNames({ dirty: formState.email.dirty })}
                 type="email"
                 name="email"
                 autoComplete="email"
                 placeholder={intl.formatMessage(formTranslations.emailPlaceholder)}
                 value={formState.email.value || ""}
                 onChange={e => onInputChange(e.target.name, e.target.value)}
                 onBlur={e => onInputBlur(e.target.name, e.target.value)} />
          {formState.email.dirty &&
            <p className="rf-error">
              {(!formState.email.value || formState.email.value.length == 0)
                ? intl.formatMessage(formTranslations.emptyError)
                : intl.formatMessage(formTranslations.mailError)
              }
            </p>
          }
        </div>

        <div className="rf-valid-group">
          <input type="tel"
                 name="phone"
                 autoComplete="tel"
                 placeholder={intl.formatMessage(formTranslations.phonePlaceholder)}
                 value={formState.phone.value || ""}
                 onChange={e => onInputChange(e.target.name, e.target.value)}
                 onBlur={e => onInputBlur(e.target.name, e.target.value)} />
          {formState.phone.dirty &&
            <p className="rf-error">
              {(!formState.phone.value || formState.phone.value.length == 0)
                ? intl.formatMessage(formTranslations.emptyError)
                : intl.formatMessage(formTranslations.phoneError)
              }
            </p>
          }
        </div>

      </div>

      <div className="r-form">

        <div className="rf-valid-group">
          <div className="rf-line-group">
            <input className={classNames({ dirty: formState.street.dirty })}
                   type="text"
                   name="street"
                   placeholder={intl.formatMessage(formTranslations.streetPlaceholder)}
                   value={formState.street.value || ""}
                   onChange={e => onInputChange(e.target.name, e.target.value)}
                   onBlur={e => onInputBlur(e.target.name, e.target.value)} />
            <input className="street-min"
                   type="text"
                   name="streetNumber"
                   placeholder={intl.formatMessage(formTranslations.streetNumberPlaceholder)}
                   value={formState.streetNumber.value || ""}
                   onChange={e => onInputChange(e.target.name, e.target.value)}
                   onBlur={e => onInputBlur(e.target.name, e.target.value)} />
          </div>
          {formState.street.dirty &&
            <p className="rf-error">{intl.formatMessage(formTranslations.emptyError)}</p>
          }
        </div>

        <div className="rf-valid-group">
          <input type="text"
                 name="streetComplement"
                 placeholder={intl.formatMessage(formTranslations.streetComplementPlaceholder)}
                 value={formState.streetComplement.value || ""}
                 onChange={e => onInputChange(e.target.name, e.target.value)}
                 onBlur={e => onInputBlur(e.target.name, e.target.value)} />
        </div>

        <div className="rf-valid-group">
          <div className="rf-line-group">
            <input className={classNames("zip-min", { dirty: formState.zip.dirty })}
                   type="text"
                   name="zip"
                   autoComplete="postal-code"
                   placeholder={intl.formatMessage(formTranslations.zipPlaceholder)}
                   value={formState.zip.value || ""}
                   onChange={e => onInputChange(e.target.name, e.target.value)}
                   onBlur={e => onInputBlur(e.target.name, e.target.value)} />
            <input className={classNames({ dirty: formState.city.dirty })}
                   type="text"
                   name="city"
                   placeholder={intl.formatMessage(formTranslations.cityPlaceholder)}
                   value={formState.city.value || ""}
                   onChange={e => onInputChange(e.target.name, e.target.value)}
                   onBlur={e => onInputBlur(e.target.name, e.target.value)} />
          </div>
          {(formState.zip.dirty || formState.city.dirty) &&
            <p className="rf-error">{intl.formatMessage(formTranslations.emptyError)}</p>
          }
        </div>

        <div className="rf-valid-group">
          <select className={classNames({ dirty: formState.country.dirty })}
                  name="country"
                  value={formState.country.value || ""}
                  onChange={e => onInputChange(e.target.name, e.target.value)}
                  onBlur={e => onInputBlur(e.target.name, e.target.value)}
                  required>
            <option value="">{intl.formatMessage(formTranslations.countryPlaceholder)}</option>
            {Object.entries(countries).map(([ val, label ], i) =>
              <option key={i} value={val}>{label}</option>
            )}
          </select>
          {formState.country.dirty &&
            <p className="rf-error">{intl.formatMessage(formTranslations.emptyError)}</p>
          }
        </div>

      </div>

    </form>);

}

export default forwardRef<HTMLFormElement, PaymentInformationFormProps>(PaymentInformationForm);
