import React, {useCallback} from "react";
import {IconChevronLeft, IconChevronRight, IconDots} from "@tabler/icons-react";
import { classNames } from "@ct-react/core";
import {LocaleLink, useLocaleUrl} from "@ct-react/locale";
import {DataProps} from "../../tools/components";
import "./result-pagination.scss";
import {Link, useLocation} from "react-router-dom";

type PageInfoProps = {
  pageSize: number;
  pageCount: number;
  page: number;
  previousCursor?: string;
  nextCursor?: string;
  linkCursors?: { page: number, cursor: string }[];
  lastCursor?: string;
}

type ResultPaginationProps = DataProps<PageInfoProps> & {
  urlParams: URLSearchParams;
  onPageChange?: () => void;
}

const ResultPagination = (
  {
    className,
    data,
    urlParams,
    onPageChange = () => void 0
  }: ResultPaginationProps
) => {

  const location = useLocation();
  const buildPageUrl = useCallback((index: "first" | "last" | "previous" | "next" | number): string => {
    const copy = new URLSearchParams(location.search);
    copy.delete("page_before_cursor");
    copy.delete("page_after_cursor");
    switch (index) {
      case "previous":
        !!data.previousCursor && copy.set("page_before_cursor", data.previousCursor!);
        break;
      case "next":
        copy.set("page_after_cursor", data.nextCursor!);
        break;
      case "last":
        copy.set("page_after_cursor", data.lastCursor!);
        break;
    }
    if (typeof index === "number")
      copy.set("page_after_cursor", data.linkCursors!.find(l => l.page === index)!.cursor);
    return copy.size > 0 ? `${location.pathname}?${copy.toString()}` : location.pathname;
  }, [ location, data ]);

  if (data.pageCount < 2)
    return null;

  const wrapperClasses = classNames("rls-pagination-wrapper", className);

  return (
    <div className={wrapperClasses}>
      <ul>
        <li>
          <Link to={buildPageUrl(data.page - 1 === 1 ? "first" : "previous")}
             aria-disabled={data.page === 1}
             onClick={() => { if (data.page === 1) return; onPageChange(); }}>
              <IconChevronLeft />
          </Link>
        </li>
        <li className={classNames({ current: data.page === 1 })}>
          <Link to={buildPageUrl("first")}
                aria-disabled={data.page === 1}
                onClick={() => { if (data.page === 1) return; onPageChange(); }}>1</Link>
        </li>
        {!!data.linkCursors && data.linkCursors[0].page > 2 &&
          <li>
            <button type="button" className="dots"><IconDots /></button>
          </li>
        }
        {!!data.linkCursors && data.linkCursors.map(({ page: p }, i) => (
          <li key={i}
              className={classNames({ current: data.page === p })}>
            <Link to={buildPageUrl(p)}
                  aria-disabled={data.page === p}
                  onClick={() => { if (data.page === p) return; onPageChange(); }}>{p}</Link>
          </li>
        ))}
        {!!data.linkCursors && data.linkCursors[data.linkCursors.length - 1].page < data.pageCount - 1 &&
          <li>
            <button type="button" className="dots"><IconDots /></button>
          </li>
        }
        <li className={classNames({ current: data.page === data.pageCount })}>
          <Link to={buildPageUrl("last")}
                aria-disabled={data.page === data.pageCount}
                onClick={() => { if (data.page === data.pageCount) return; onPageChange(); }}>{data.pageCount}</Link>
        </li>
        <li>
          <Link to={buildPageUrl(data.page + 1 === data.pageCount ? "last" : "next")}
                aria-disabled={data.page === data.pageCount}
                onClick={() => { if (data.page === data.pageCount) return; onPageChange(); }}>
            <IconChevronRight />
          </Link>
        </li>
      </ul>
    </div>);

}

export default ResultPagination;
