import { Period } from "@ct-react/core";
import moment from "moment";

export type OpenArticleQuery = {
  checkIn?: moment.Moment;
  checkOut?: moment.Moment;
}

export const parseRefIdQueryParam = (params: URLSearchParams): string | undefined => params.get("refId") ?? undefined;

export const parseOpenArticleQueryParams = (params: URLSearchParams): Period => {
  const checkIn = params.get("checkin");
  const checkOut = params.get("checkout");
  return {
    ...(!!checkIn) && { start: moment(checkIn, "YYYY-MM-DD") },
    ...(!!checkOut) && { end: moment(checkOut, "YYYY-MM-DD") }
  };
}

export const rebuildOpenArticleQueryParams = (query: Period, currentParams: URLSearchParams) => {
  Array.from(currentParams.keys())
    .filter(pk => pk.match(/^check(in|out)$/))
    .forEach(pk => currentParams.delete(pk));
  if (!!query.start) currentParams.set("checkin", query.start.format("YYYY-MM-DD"));
  if (!!query.end) currentParams.set("checkout", query.end.format("YYYY-MM-DD"));
}
