import React from "react";
import {defineMessages, useIntl} from "react-intl";
import Skeleton from "react-loading-skeleton";
import {Helmet} from "react-helmet-async";
import {UpdateUser, User} from "../../../shared/models/user";
import {useLocalFetcher, usePostLocalFetcher} from "../../hooks/local-fetcher";
import Wrapper from "../../components/layout/wrapper";
import UserProfileForm from "../../components/forms/user-profile";
import "./profile.scss";

const transDefs = defineMessages({
  title: { id: "seo-title-user-profile", defaultMessage: "Mon profil utilisateur" }
});

const ProfilePage = () => {

  const intl = useIntl();
  const { data, loading } = useLocalFetcher<User>("/user");
  const [ update ] = usePostLocalFetcher<UpdateUser, User>("/user");

  return (
    <Wrapper.Centered as="main" className="rl-profile">
      <Helmet>
        <title>{intl.formatMessage(transDefs.title)}</title>
        <meta name="robots" content="noindex" />
      </Helmet>
      <Wrapper.InnerTitle as="h1">{intl.formatMessage(transDefs.title)}</Wrapper.InnerTitle>
      {!loading
        ? (
          <div className="form-wrapper">
            <UserProfileForm user={data} update={update} />
          </div>)
        : <Skeleton className="profile-skeleton" />
      }
    </Wrapper.Centered>);

};

export default ProfilePage;
