import React, { useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { gql, makeReference, useMutation, useQuery } from "@apollo/client";
import { defineMessages, useIntl } from "react-intl";
import Skeleton from "react-loading-skeleton";
import moment from "moment";
import {
  AnyPeriod,
  BookPeriod,
  classNames,
  cleanPrice,
  isBookPeriod,
  Period,
  useAppConfig,
  useIsMounted
} from "@ct-react/core";
import { useNavigate } from "@ct-react/locale";
import { useVisitor } from "@ct-react/visitor";
import { CustomViewType } from "@shared/models/app-context";
import { buildArticleUrl } from "@shared/urls";
import {
  CART_ITEM_FIELDS,
  CART_PRICE_FIELDS,
  COST_DESCRIPTIONS_FIELDS,
  DETAILED_FEATURES_FIELDS,
  FULL_AGENCY_FIELDS,
  IMAGE_FIELDS,
  MAIN_FEATURES_FIELDS,
  REVIEW_RATINGS_FIELDS
} from "@shared/gql/fragments";
import { useDisplayUntilTablet } from "../tools/breakpoints";
import {
  parseOpenArticleQueryParams,
  parseRefIdQueryParam,
  rebuildOpenArticleQueryParams
} from "../tools/search-params/open-article";
import { Agency as AgencyModel } from "../models/agency";
import { AddCartItem, AddCartOptionItem } from "../models/cart";
import { isCustomOptionDef } from "../models/options";
import NotFound from "./not-found";
import Wrapper from "../components/layout/wrapper";
import SeoHelmet from "../components/seo-helmet";
import { MapContainer, MapMarker } from "../code-splitting/map-implement";
import Title from "../components/article/parts/title";
import Description from "../components/article/parts/description";
import FullFeatures from "../components/article/parts/full-features";
import Images from "../components/article/parts/images";
import Costs from "../components/article/parts/costs";
import Reviews from "../components/article/parts/reviews";
import Agency from "../components/article/parts/agency";
import BookingArticlePreview from "../components/article/booking/article-preview";
import BookingBox from "../components/article/booking-box";
import Comment from "../components/cards/comment";
import OptionsSelection, { OptionsSelectionData } from "../components/article/options-selection";
import Modal, { InnerModal, ModalHandle } from "../components/common/modal";
import AllReviews from "../components/article/parts/all-reviews";
import "./open-article.scss";

const transDefs = defineMessages({
  reviewTitle: { id: "booking-comments-title", defaultMessage: "Commentaires clients" },
  reviewLink: { id: "booking-reviews-modal-link", defaultMessage: "Voir tous les commentaires" }
});
const ARTICLE_SEO_GQL_DATA = gql`
  query GetArticleSEOData($articleId: ID!) {
    seo: articleSEO(articleId: $articleId) { title { value } description { value matchRequestedLang } image keywords translatableKeywords { key value } }
    seoSummary: articleSummary(articleId: $articleId) { id slug agency { slug } }
  }
`;

const ALL_ARTICLE_GQL_DATA = gql`
  ${IMAGE_FIELDS}
  ${MAIN_FEATURES_FIELDS}
  ${FULL_AGENCY_FIELDS}
  ${DETAILED_FEATURES_FIELDS}
  ${COST_DESCRIPTIONS_FIELDS}
  ${REVIEW_RATINGS_FIELDS}
  query GetArticle($articleId: ID!, $commentsLength: Int) {
    summary: articleSummary(articleId: $articleId) { id slug title { value } images { ...ImageFields } location { resume } coordinates features { ...MainFeaturesFields } agency { ...FullAgencyFields } classification lowestPriceType lowestPrice { amount currency } }
    description: articleDescription(articleId: $articleId) { value matchRequestedLang }
    features: articleFeatures(articleId: $articleId) { ...DetailedFeaturesFields }
    costDescriptions: articleCostDescriptions(articleId: $articleId) { ...CostDescriptionsFields }
    reviews: articleReviews(articleId: $articleId, commentsLength: $commentsLength) { averageRate, rates { ...ReviewRatingsFields } commentsCount comments { customerName date comment averageRate withAnswer } }
  }
`;

const CHECK_OPTIONS_GQL_DATA = gql`
  ${IMAGE_FIELDS}
  query CheckOptions($item: RentalAccommodationOptionablesFilterInput!) {
    options: cartCheckOptions(item: { rentalAccommodation: $item }) {
      ... on OptionableBenefit {
        id title { value } description { value } images { ...ImageFields } forceSelection
        criteria { quantity } grid { quantity price { amount currency } } }
    }
  }
`;

const ADD_TO_CART_GQL_MUT = gql`
  ${CART_ITEM_FIELDS}
  ${CART_PRICE_FIELDS}
  mutation AddToCart($sessionId: String, $userId: String, $item: AddRentalAccommodationCartItemInput!, $options: [AddCartItemOptionInput!]) {
    cartAddItem(sessionId: $sessionId, userId: $userId, item: { rentalAccommodation: $item }, options: $options) {
      cartId itemId item { ...CartItemFields } fullPrice { ...CartPriceFields }
    }
  }
`;

const OpenArticle = () => {

  const intl = useIntl();
  const isMounted = useIsMounted();
  const navigate = useNavigate();
  const { sessionId, userId } = useVisitor();
  const { options: { customView } } = useAppConfig();
  const commentsLength = 5;

  // handle entry query definition

  const [searchParams, setSearchParams] = useSearchParams();
  const [queryParams, setQueryParams] = useState<Period>(parseOpenArticleQueryParams(searchParams));

  // component state

  const reviewsRef = useRef<HTMLDivElement>(null);
  const modalReviewsRef = useRef<ModalHandle>(null);
  const agencyRef = useRef<HTMLDivElement>(null);

  const [ failure, setFailure ] = useState<boolean>(!parseRefIdQueryParam(searchParams));
  const [ articleId, setArticleId ] = useState<string | undefined>(parseRefIdQueryParam(searchParams));
  const [ loading, setLoading ] = useState<boolean>(true);
  const [ content, setContent ] = useState<any | null>(null);
  const [ bookingSelection, setBookingSelection ] = useState<BookPeriod | undefined>(undefined);

  const [ actionLoading, setActionLoading ] = useState<boolean>(false);
  const [ optionsSelectionData, setOptionsSelectionData ] = useState<OptionsSelectionData | undefined>(undefined);

  const [ openReviewModal, setOpenReviewModal ] = useState<boolean>(false);

  // component effects
  // region

  // handle all display on article id change

  useEffect(() => {
    if (!isMounted) return;
    const refId = parseRefIdQueryParam(searchParams);
    !!refId ? setArticleId(refId) : setFailure(true);
  }, [ searchParams ]);

  // active loading view on article id change

  useEffect(() => {
    if (!isMounted) return;
    setLoading(true);
  }, [ articleId ]);

  // endregion

  // data loading & mutation
  // region

  const { data: seoData } = useQuery(ARTICLE_SEO_GQL_DATA, { variables: { articleId } });

  const { client: apollo } = useQuery(ALL_ARTICLE_GQL_DATA, {
    variables: { articleId, commentsLength },
    skip: !articleId,
    ssr: false,
    onError: () => setFailure(true),
    onCompleted: ({
      summary,
      description,
      features,
      costDescriptions: cleanableCostDescriptions,
      reviews
    }) => {
      const { __typename, ...costDescriptions } = cleanableCostDescriptions;
      setContent({ summary, description, features, costDescriptions, reviews });
      setLoading(false);
    }
  });

  const [ addToCart ] = useMutation(ADD_TO_CART_GQL_MUT, {
    onCompleted: () => navigate("/cart"),
    update: (cache, { data: { cartAddItem } }) => {
      cache.modify({
        id: cache.identify(makeReference(`Cart:${cartAddItem.cartId}`)),
        fields: {
          items: () => [ cartAddItem.item ],
          itemsCount: () => 1,
          fullPrice: () => cartAddItem.fullPrice
        }
      })
    },
    refetchQueries: [ "CartBadge" ],
    awaitRefetchQueries: true
  });

  // endregion

  // dom and component events interactions
  // region

  const onRateClick = () => reviewsRef?.current?.scrollIntoView({ behavior: "smooth", block: "center" });

  const onBookingChoiceChange = (period: AnyPeriod) => {
    setBookingSelection(isBookPeriod(period) ? period : undefined);
    rebuildOpenArticleQueryParams(period, searchParams);
    setSearchParams(searchParams, { replace: true });
    setQueryParams(period);
  }

  const onAction = (choice?: BookPeriod) => {
    if (!choice || !isBookPeriod(choice)) return;
    if (!choice.bookable || choice.price === "onDemand") {
      agencyRef?.current?.scrollIntoView({ behavior: "smooth", block: "center" });
      return;
    }

    setActionLoading(true);
    const cartItem = {
      articleId: articleId!,
      price: cleanPrice(choice.price),
      checkIn: choice.start.format("YYYY-MM-DD"),
      checkOut: choice.end.format("YYYY-MM-DD")
    }
    const { price, ...item } = cartItem;

    apollo.query({
      query: CHECK_OPTIONS_GQL_DATA,
      variables: { item },
      fetchPolicy: "network-only"
    }).then(({ data: { options: dataOptions } }) => {

      const options = dataOptions.filter(isCustomOptionDef);
      if (options.length === 0) {
        return addToCart({
          variables: {
            ...(!userId) && { sessionId },
            ...(!!userId) && { userId },
            item: cartItem
          }
        })
      }

      return new Promise(res => {
        setOptionsSelectionData({
          cartItem,
          resume: {
            title: content.summary.title.value,
            location: content.summary.location.resume,
            thumbnail: content.summary.images.find(i => i.isMain),
            period: choice as Period
          },
          optionDefs: options
        });
        return res(void 0);
      });

    }).finally(() => setActionLoading(false));
  }

  const onOptionsSelected = (item: AddCartItem, options: AddCartOptionItem[]) => {
    addToCart({
      variables: {
        ...(!userId) && { sessionId },
        ...(!!userId) && { userId },
        item,
        options: options.map(o => ({ benefit: o }))
      }
    }).finally(() => setOptionsSelectionData(undefined));
  }

  const onReviewMoreClick = (index: number) => {
    setOpenReviewModal(true);
    modalReviewsRef.current?.scrollToAnchor(`comment-${index}`);
  }

  // endregion

  // rendering
  // region

  const withPermanentBookingBox = useDisplayUntilTablet();
  const contentColClasses = classNames("rla-content", { "rla-two-col": !withPermanentBookingBox });
  const bookingClasses = classNames("rla-sticky-booking", { hidden: loading, mobile: withPermanentBookingBox });
  const mapBoxClasses = classNames("map-box", "back", { imaged: !loading && customView === CustomViewType.HOTEL && !!content.summary.agency.id });

  if (failure)
    return <NotFound />;

  return (
    <main className="rl-article">

      {!!seoData && <SeoHelmet {...{
        ...seoData.seo,
        canonical: buildArticleUrl(seoData.seoSummary)
      }} />}

      <OptionsSelection data={optionsSelectionData} onSelection={onOptionsSelected} />

      <Wrapper.Centered as="section" className="rla-map">
        {loading
          ? <Skeleton className="map-box" inline={true} />
          : (
            <div className={mapBoxClasses}
                 {...(customView === CustomViewType.HOTEL) && { style: {
                   backgroundImage : `url(/assets/custom-view/hotel-${content.summary.agency.id}.webp)`
                 } }}>
              {customView !== CustomViewType.HOTEL &&
                <MapContainer zoom={16} center={content.summary.coordinates} layersControlPosition="bottomleft">
                  <MapMarker position={content.summary.coordinates} />
                </MapContainer>
              }
            </div>)
        }
      </Wrapper.Centered>

      <Wrapper.Centered className={contentColClasses}>

        <article className="rla-data">
          <Title {...{
            loading,
            className: "rla-separator",
            ...(!loading) && {
              data: {
                title: content.summary.title.value,
                location: content.summary.location.resume,
                thumbnail: content.summary.images.find((i: any) => i.isMain),
                ...(!!content.reviews?.averageRate) && { rate: content.reviews.averageRate },
                ...(!!content.summary.lowestPrice) && {
                  lowestPrice: { type: content.summary.lowestPriceType, price: content.summary.lowestPrice }
                },
                bookingSelection
              },
              onRateClick
            }
          }} />
          <Description {...{
            loading,
            className: "rla-separator blank",
            ...(!loading) && {
              data: {
                classification: content.summary.classification,
                description: content.description
              }
            }
          }} />
          <Images {...{
            loading,
            className: "rla-separator",
            ...(!loading) && { data: content.summary.images }
          }} />
          <FullFeatures {...{
            loading,
            className: "rla-separator",
            ...(!loading) && { data: content.features }
          }} />
          <Costs {...{
            loading,
            className: "rla-separator",
            ...(!loading) && { data: content.costDescriptions }
          }} />
        </article>

        {(!loading && !!articleId) &&
          <aside className={classNames(bookingClasses)}>
            {!withPermanentBookingBox && <BookingArticlePreview loading={false} data={content.summary} />}
            <BookingBox articleId={articleId}
              mobileView={withPermanentBookingBox}
              processing={actionLoading}
              bookingChoice={queryParams}
              onBookingChoiceChange={onBookingChoiceChange}
              onAction={onAction} />
          </aside>
        }

      </Wrapper.Centered>

      <div className="rla-complements">

        {(!loading && !!content.reviews) &&
          <>
            <Wrapper.Centered className="rla-reviews-wrapper">
              <div className="rla-separator">
                <Reviews ref={reviewsRef}
                  {...{
                    loading: false,
                    data: content.reviews
                  }} />
              </div>
            </Wrapper.Centered>
            {content.reviews.comments.length > 0 &&
              <Wrapper.Slideable data-nosnippet={true}
                                 className="rla-comments-wrapper"
                                 wrapperClassName="rla-comments grid"
                                 title={intl.formatMessage(transDefs.reviewTitle)}>
                {content.reviews.comments.map((comment, i) =>
                  <Comment key={i}
                           data={{ ...comment, date: moment(comment.date)}}
                           moreable={() => onReviewMoreClick(i)} />
                )}
                <Modal ref={modalReviewsRef}
                       size="lg"
                       open={openReviewModal}
                       onOpenChange={setOpenReviewModal}>
                  <InnerModal.Trigger as="div" className="rw-card show-all">
                    <span className="more">
                      {intl.formatMessage(transDefs.reviewLink)}
                    </span>
                  </InnerModal.Trigger>
                  <InnerModal.Content title={intl.formatMessage(transDefs.reviewTitle)}>
                    <AllReviews articleId={articleId!} />
                  </InnerModal.Content>
                </Modal>
              </Wrapper.Slideable>
            }
          </>
        }

        {(!loading && !!content.summary && customView !== CustomViewType.HOTEL) &&
          <Wrapper.Centered>
            <div className="rla-separator">
              <Agency ref={agencyRef}
                      {...{
                        loading: false,
                        data: {
                          agency: content.summary.agency as AgencyModel,
                          articleId: articleId,
                          bookingChoice: queryParams
                        }
                      }} />
            </div>
          </Wrapper.Centered>
        }

      </div>
    </main>);

  // endregion

}

export default OpenArticle;
