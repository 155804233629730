import React, { FormEvent, useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { IconInfoSquareRoundedFilled, IconLoader3 } from "@tabler/icons-react";
import { classNames, cleanPrice, Price } from "@ct-react/core";
import { bookingTranslations, formTranslations, globalTranslations } from "../../i18n/sharable-defs";
import { ResumeBookChoice } from "../../models/article";
import { AnyOptionDef, BenefitGridItem } from "../../models/options";
import { AddCartItem, AddCartOptionItem } from "../../models/cart";
import { Ratio } from "../../models/images";
import { FormInputConfigDefinition, notEmptyFormValidator } from "../../models/form";
import { useFormValidator } from "../../hooks/form";
import Modal, { InnerModal } from "../common/modal";
import { Button } from "../common/minimals";
import RatioImage from "../common/ratio-image";
import Tooltip, { InnerTooltip } from "../common/tooltip";
import "./options-selection.scss";

const toFormDefinition = (options?: AnyOptionDef[]): Record<string, FormInputConfigDefinition> => {
  const values = (options || []).map(opt => {
    const def = {
      defaultValue: !opt.forceSelection ? opt.criteria.quantity[0] : null,
      ...(opt.forceSelection) && { validator: notEmptyFormValidator }
    };
    return [ opt.id, def ];
  })
  return Object.fromEntries(values);
}

const toGrid = (options?: AnyOptionDef[]): Record<string, BenefitGridItem[]> =>
  Object.fromEntries((options || []).map(opt => ([ opt.id, opt.grid ])));

export type OptionsSelectionData = {
  cartItem: AddCartItem;
  resume: ResumeBookChoice;
  optionDefs: AnyOptionDef[];
};

type OptionsSelectionProps = {
  readonly data?: OptionsSelectionData;
  onSelection: (item: AddCartItem, options: AddCartOptionItem[]) => void;
};

const OptionFormItem = ({def: option, state, onBlur, onChange }) => {

  const intl = useIntl();
  const selectClasses = classNames({ dirty: state.dirty });

  return (
    <>
      <div className="option-img">
        {option.images.length > 0 &&
          <RatioImage images={option.images.find(i => i.isMain)!}
                      alt={option.title.value}
                      ratio={Ratio.SQUARE}/>
        }
      </div>
      <div className="option-desc">
        <div className="option-title">
          {option.title.value}
          {!!option.description &&
            <Tooltip>
              <InnerTooltip.Trigger className="icon"><IconInfoSquareRoundedFilled/></InnerTooltip.Trigger>
              <InnerTooltip.Content>
                <div className="r-format-html" dangerouslySetInnerHTML={{__html: option.description.value}}/>
              </InnerTooltip.Content>
            </Tooltip>
          }
        </div>
        <div className="option-criterion">
          <select className={selectClasses}
                  name={option.id}
                  value={state?.value ?? ""}
                  onBlur={e => onBlur(e.target.value === "" ? null : +e.target.value)}
                  onChange={e => onChange(e.target.value === "" ? null : +e.target.value)}>
            {option.forceSelection && <option value="" disabled={true}>-</option>}
            {option.criteria.quantity.map((v: number, i: number) => <option key={i} value={v}>{v}</option>)}
          </select>
          {state.dirty &&
            <p className="rf-error">{intl.formatMessage(formTranslations.optionError)}</p>
          }
        </div>
      </div>
    </>);

}

const OptionsSelection = (
  {
    data,
    onSelection
  }: OptionsSelectionProps) => {

  const intl = useIntl();

  // component states

  const formDefinition = useMemo(() => toFormDefinition(data?.optionDefs), [data]);
  const grid = useMemo(() => toGrid(data?.optionDefs), [data]);

  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [selection, setSelection ] = useState<Record<string, Price>>({});
  const { formData, formState, onInputChange, onInputBlur, isValid } = useFormValidator(formDefinition, true);

  useEffect(() => {
    setModalOpen(!!data && data.optionDefs.length > 0);
    setLoading(false);
  }, [ data ]);

  useEffect(() => {
    const selectionEntries = Object.entries(formData).map(([ optionId, quantity ]) => ([
        optionId,
      (!quantity || quantity === 0)
        ? { amount: 0, currency: data!.cartItem.price.currency }
        : cleanPrice(grid[optionId].find(g => g.quantity === quantity)!.price)
    ]));
    setSelection(Object.fromEntries(selectionEntries));
  }, [ formData ]);

  // dom interactions

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();
    if (!isValid) return;

    setLoading(true);
    onSelection(data!.cartItem, Object.entries(selection)
      .map(([ optionId, price ]) => ({ optionId, price }))
      .filter(r => r.price.amount > 0));
  }

  // rendering

  if (!data)
    return null;

  const formClasses = classNames("options-form", { "with-image": data.optionDefs?.some(opt => !!opt.images) });

  const submitter = (
    <>
      <Button type="button"
              className="link bolder small"
              onClick={() => setModalOpen(false)}>
        {intl.formatMessage(globalTranslations.cancel)}
      </Button>
      <Button type="submit"
              className={classNames("bolder loadable-keeping-size", { loading: modalOpen && loading })}
              disabled={!isValid}>
        <span className="text">{intl.formatMessage(globalTranslations.continue)}</span>
        <div className="loader"><IconLoader3 /></div>
      </Button>
    </>);

  return (
    <Modal open={modalOpen}
           onOpenChange={setModalOpen}>

      <InnerModal.Content as="form"
                          className="rla-options r-form"
                          onSubmit={onSubmit}
                          title={intl.formatMessage(bookingTranslations.benefits)}
                          footer={submitter}>

        <p>
          {intl.formatMessage({
            id: "booking-picker-benefits-intro",
            defaultMessage: "L'agence vous propose des prestations qui peuvent compléter votre réservation."
          })}
        </p>

        <div className={formClasses}>
          {data.optionDefs.map((opt, i) => (
            <div key={i}
                 className="one-option">
              <OptionFormItem def={opt}
                              state={formState[opt.id]}
                              onBlur={(val: any) => onInputBlur(opt.id, val)}
                              onChange={(val: any) => onInputChange(opt.id, val)}/>
              <span className="amount">
                  {intl.formatNumber(selection[opt.id]?.amount || 0, {
                    style: "currency",
                    currency: selection[opt.id]?.currency || data.cartItem.price.currency
                  })}
                </span>
            </div>))
          }
        </div>

      </InnerModal.Content>

    </Modal>);

}

export default OptionsSelection;
