import React, {useMemo} from "react";
import { Helmet } from "react-helmet-async";
import { SkeletonTheme } from "react-loading-skeleton";
import { IntlProvider } from "react-intl";
import { Elements as StripeElements } from "@stripe/react-stripe-js";
import { CheckoutLocale, loadStripe } from "@stripe/stripe-js";
import { CookieConsentProvider } from "@use-cookie-consent/react";
import {useAppConfig} from "@ct-react/core";
import { defaultLocale, useLocaleContext } from "@ct-react/locale";
import { getIntlMessages } from "./i18n";
import LocaleRoutes from "./routes";
import Header from "./components/layout/header";
import Footer from "./components/layout/footer";
import { CookieProvider } from "./code-splitting/client-only-ui";
import "../scss/global.scss";

const cookieConsentOptions = { consentCookieAttributes: { expires: 180 } };

const App = () => {

  const { locale } = useLocaleContext();
  const { paymentApiKey } = useAppConfig();

  const stripeLoading = useMemo(() => loadStripe(paymentApiKey), [ paymentApiKey ]);

  return (
    <IntlProvider locale={locale.code}
                  defaultLocale={defaultLocale.code}
                  messages={getIntlMessages(locale)}>
      <CookieConsentProvider useCookieConsentHooksOptions={cookieConsentOptions}>
        <Helmet>
          <html lang={locale.code} />
          <meta property="og:locale" content={locale.code} />
          <meta property="og:type" content="website" />
          <meta property="og:site_name" content="rentalp.com" />
        </Helmet>
        <CookieProvider />
        <SkeletonTheme baseColor="var(--ctr-skeleton-bg)" highlightColor="var(--ctr-skeleton-hl)">
          <StripeElements stripe={stripeLoading} options={{ locale: locale.basename as CheckoutLocale }}>
            <Header />
            <div className="main-wrap">
              <LocaleRoutes />
            </div>
            <Footer />
          </StripeElements>
        </SkeletonTheme>
      </CookieConsentProvider>
    </IntlProvider>);

};

export default App;
