import React, {useEffect} from "react";
import {defineMessages, useIntl} from "react-intl";
import {gql, useMutation} from "@apollo/client";
import {useOutletContext, useSearchParams} from "react-router-dom";
import {IconShoppingCartCancel} from "@tabler/icons-react";
import {useNavigate} from "@ct-react/locale";
import { cartTranslations } from "../../i18n/sharable-defs";
import {CartContext} from "./cart-outlet";
import SuccessSvg from "../../assets/componable-svgs/succeeded-payment.svg";
import "./common.scss"
import "./payment-callback.scss";

const transDefs = defineMessages({
  success: { id: "cart-process-payment-callback-success-title", defaultMessage: "Paiement réussi" },
  successText: {
    id: "cart-process-payment-callback-success-text",
    defaultMessage: "Merci pour votre réservation. L'agence se fera un plaisir de vous recevoir."
  },
  failure: { id: "cart-process-payment-callback-failure-title", defaultMessage: "Paiement annulé" },
  failureText: {
    id: "cart-process-payment-callback-failure-text",
    defaultMessage: "Vous avez annulé le processus de paiement. Votre réservation reste en attente auprès de l'agence jusqu'à confirmation."
  }
});

const CANCEL_PAYMENT_MUT = gql`
  mutation CancelPayment($sessionId: String!) {
    cartPaymentCancelation(sessionId: $sessionId)
  }
`;

type CartPaymentCallbackProps = { succeeded: boolean };

const CartPaymentCallback = ({ succeeded }: CartPaymentCallbackProps) => {

  const intl = useIntl();
  const navigate = useNavigate();

  const [ searchParams ] = useSearchParams();
  const { changeIndex } = useOutletContext<CartContext>();

  const [ cancelSession ] = useMutation(CANCEL_PAYMENT_MUT);

  useEffect(() => {
    changeIndex(3);
    if (!succeeded && searchParams.has("session_id"))
      cancelSession({ variables: { sessionId: searchParams.get("session_id") } }).then();
  }, []);

  return (
    <div className="cart-item-wrapper callback">
      <div className="result">
        <h1>{intl.formatMessage(succeeded ? transDefs.success : transDefs.failure)}</h1>
        {succeeded ? <SuccessSvg className="success-svg" /> : <IconShoppingCartCancel />}
        <p>{intl.formatMessage(succeeded ? transDefs.successText : transDefs.failureText)}</p>
      </div>
      <div className="stepper-actions">
        <button className="back"
                type="button"
                onClick={() => navigate("/")}>
          {intl.formatMessage(cartTranslations.backHome)}
        </button>
      </div>
    </div>);

}

export default CartPaymentCallback;
