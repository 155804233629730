import React, {useMemo} from "react";
import {useIntl} from "react-intl";
import Skeleton from "react-loading-skeleton";
import {classNames} from "@ct-react/core";
import {SkeletonableProps} from "../../../tools/components";
import {DescriptionScalar} from "../../../models/description";
import {LangWarning} from "../../common/info-box";
import ClassificationSvg from "../../../assets/componable-svgs/stv-classifications.svg";
import "./description.scss";

interface DescriptionData {
  classification?: any;
  description: DescriptionScalar;
}

type DescriptionProps = SkeletonableProps<DescriptionData>;

const Description = (
  {
    loading,
    className,
    data
  }: DescriptionProps) => {

  const intl = useIntl();
  const emptyTranslations = intl.formatMessage({
    id: "booking-no-description",
    defaultMessage: "Il n'y a aucune description pour ce logement."
  });

  const descriptionRendering = useMemo(() => {
    if (!!data?.description)
      return <>
        {!data.description.matchRequestedLang && <LangWarning />}
        <div className="r-format-html" dangerouslySetInnerHTML={{ __html: data.description.value }} />
      </>;
    return <LangWarning msg={emptyTranslations} />;
  }, [ data ]);

  const wrapperClasses = classNames("rla-description", className);
  const classificationClasses = classNames("rla-classification", className);

  if (loading) return (
    <div className={wrapperClasses}>
      <Skeleton containerClassName="r-skeleton" count={3} />
      <Skeleton containerClassName="r-skeleton" width="25%" />
      <br/>
      <Skeleton containerClassName="r-skeleton" count={4} />
      <Skeleton containerClassName="r-skeleton" width="65%" />
    </div>);

  return (
    <>
      <div className={wrapperClasses}>
        <h2>
          {!loading
            ? intl.formatMessage({
              id: "booking-description-title",
              defaultMessage: "À propos de ce logement"
            })
            : <Skeleton inline={true} width="50%" />
          }
        </h2>
        {descriptionRendering}
      </div>
      {(!!data!.classification && data!.classification !== "NONE") &&
        <div className={classificationClasses}>
          <h2>
            {intl.formatMessage({
              id: "booking-classification-label",
              defaultMessage: "Classification"
          })}
          </h2>
          <ClassificationSvg className={data!.classification.toLowerCase()} />
        </div>
      }
    </>);

};

export default Description;
