import React from "react";
import {useQuery} from "@apollo/client";
import {defineMessages, useIntl} from "react-intl";
import {IconArrowBack, IconSearch} from "@tabler/icons-react";
import {useAppConfig} from "@ct-react/core";
import {LocaleLink} from "@ct-react/locale";
import {FULL_AGENCY_GQL_DATA} from "../../../shared/gql/queries";
import Wrapper from "./wrapper";
import {LinkAsButton} from "../common/minimals";
import InternalMenu from "./header-items/internal-menu";
import CartLink from "./header-items/cart-link";
import LangMenu from "./header-items/lang-menu";
import UserMenu from "./header-items/user-menu";
import Logo from "../../assets/rentalp-logo.svg";
import "./header.scss";

const transDefs = defineMessages({
  backButton: { id: "header-back-context", defaultMessage: "Retour à {url}" }
});

const Header = () => {

  const intl = useIntl();
  const { options: { asAgency } } = useAppConfig();

  const { data } = !!asAgency
    ? useQuery(FULL_AGENCY_GQL_DATA, { variables: { agencyId: asAgency.id } })
    : { data: { agency: null } };

  return (
    <Wrapper.Centered as="header" className="rl-header">

      <div className="main">
        <h1>
          <LocaleLink to="/">
            {(!!data?.agency?.logo)
              ? <img className="logo" src={data.agency.logo} alt={data.agency.name} />
              : <Logo className="logo" />
            }
          </LocaleLink>
        </h1>

        <nav>
          <LocaleLink to="/resorts/any/search">
            <button type="button" className="main-nav-item">
              <IconSearch />
            </button>
          </LocaleLink>
          {!asAgency && <InternalMenu />}
          <div className="rl-h-n-features">
            <CartLink />
            <LangMenu />
            <UserMenu />
          </div>
        </nav>
      </div>

      {!!asAgency?.backContext &&
        <div className="agency-filter">
          <LinkAsButton className="back-context inline-icon tertiary bolder" href={asAgency.backContext}>
            <IconArrowBack />
            {intl.formatMessage(transDefs.backButton, { url: (new URL(asAgency.backContext)).host })}
          </LinkAsButton>
        </div>
      }
    </Wrapper.Centered>);

}

export default Header;
