import React, { useMemo } from "react";
import { useIntl } from "react-intl";
import { IconInfoSquareRoundedFilled } from "@tabler/icons-react";
import { bookingTranslations } from "../../i18n/sharable-defs";
import Tooltip, { InnerTooltip } from "../common/tooltip";
import { CartPrice as CartPriceModel } from "../../models/cart";
import "./cart-price.scss";

type CartPriceProps = CartPriceModel & {
  title?: string;
}
const CartPrice = (
  {
    title,
    ...props
  }: CartPriceProps) => {

  const intl = useIntl();

  const benefits = useMemo(() => {
    if (!props.optionDetails) return 0;
    return props.optionDetails.filter(o => o.optionType !== "CUSTOMER_CHARGE").reduce((acc, cur) => acc + cur.amount, 0);
  }, [ props ]);

  const customerCharge = useMemo(() => {
    if (!props.optionDetails) return 0;
    return props.optionDetails.filter(o => o.optionType === "CUSTOMER_CHARGE").reduce((acc, cur) => acc + cur.amount, 0);
  }, [ props ]);

  return <div className="cart-price-detail">
    <h2>
      {!!title
        ? title
        : intl.formatMessage({ id: "cart-item-price-title", defaultMessage: "Détails du prix"})
      }
    </h2>
    {(!!props.original || !!props.optionDetails) &&
      <dl className="price-list">
        <dt>{intl.formatMessage({ id: "cart-item-price-unit", defaultMessage: "Prix unitaire" })}</dt>
        <dd>{intl.formatNumber(props.original || (props.amount - benefits - customerCharge), { style: "currency", currency: props.currency })}</dd>
        {!!props.discount &&
            <>
              <dt>{intl.formatMessage({ id: "cart-item-price-discount", defaultMessage: "Réduction" })}</dt>
              <dd className="discount">{intl.formatNumber(-1 * props.discount, { style: "currency", currency: props.currency })}</dd>
            </>
        }
        {(benefits > 0) &&
          <>
            <dt>{intl.formatMessage(bookingTranslations.benefits)}</dt>
            <dd>{intl.formatNumber(benefits, { style: "currency", currency: props.currency })}</dd>
          </>
        }
        {(customerCharge > 0) &&
          <>
            <dt className="charges">
              <Tooltip placement="bottom">
                <InnerTooltip.Trigger as="span" className="tooltip-more">
                  {intl.formatMessage(bookingTranslations.customerCharge)}
                  <IconInfoSquareRoundedFilled />
                </InnerTooltip.Trigger>
                <InnerTooltip.Content>{intl.formatMessage(bookingTranslations.customerChargeTooltip)}</InnerTooltip.Content>
              </Tooltip>
            </dt>
            <dd>{intl.formatNumber(customerCharge, { style: "currency", currency: props.currency })}</dd>
          </>
        }
      </dl>
    }
    <dl className="price-list total">
      <dt>{intl.formatMessage({ id: "cart-item-price-amount", defaultMessage: "Montant" })}</dt>
      <dd>{intl.formatNumber(props.amount, { style: "currency", currency: props.currency })}</dd>
      {(props.downPayment > 0 && props.downPayment != props.amount) &&
        <>
          <dt>{intl.formatMessage({ id: "cart-item-price-down", defaultMessage: "Acompte immédiat" })}</dt>
          <dd>{intl.formatNumber(props.downPayment, { style: "currency", currency: props.currency })}</dd>
        </>
      }
    </dl>
  </div>;

}

export default CartPrice;
