import React, {
  createContext,
  ElementType,
  forwardRef,
  HTMLProps,
  PropsWithChildren,
  useContext,
  useImperativeHandle,
  useMemo,
  useState
} from "react";
import {
  autoUpdate,
  FloatingFocusManager, FloatingPortal,
  offset,
  Placement,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useMergeRefs
} from "@floating-ui/react";
import {OffsetOptions} from "@floating-ui/core/src/middleware/offset";
import {classNames} from "@ct-react/core";
import "./dropdown.scss";

type FloatingProps = {
  placement?: Placement,
  offset?: OffsetOptions
}

type DropdownProps = Required<PropsWithChildren> & FloatingProps;
type DropdownTriggerProps = HTMLProps<HTMLElement> & { as?: ElementType };
type DropdownContentProps = HTMLProps<HTMLElement>;

const useFloatingDropdown = (props: Required<FloatingProps>) => {

  const [ open, setOpen ] = useState(false);

  const floating = useFloating({
    open,
    onOpenChange: setOpen,
    whileElementsMounted: autoUpdate,
    placement: props.placement,
    middleware: [ offset(props.offset) ]
  });

  const interactions = useInteractions([
    useClick(floating.context),
    useDismiss(floating.context, {
      ancestorScroll: true,
      referencePressEvent: "click",
      outsidePressEvent: "click"
    })
  ]);

  return useMemo(() => ({ open, setOpen, floating, interactions }), [ open, floating, interactions ]);
}

const DropdownContext = createContext<ReturnType<typeof useFloatingDropdown> | undefined>(undefined);

const useDropdownContext = () => {
  const context = useContext(DropdownContext);
  if (!context) throw new Error("Dropdown component must be wrapped by dropdown context");
  return context;
}

const DropdownTrigger = (
  { as: Tag = "div",
    className,
    children,
    ...props
  }: DropdownTriggerProps) => {

  const context = useDropdownContext();
  const ref = useMergeRefs([ context.floating.reference, (children as any).ref ]);
  const wrapperClasses = classNames("r-dropdown-trigger", className);

  return (<Tag ref={ref}
               {...context.interactions.getReferenceProps(props)}
               className={wrapperClasses}>{children}</Tag>);

}

const DropdownContent = (
  {
    children,
    className,
    style,
    ...props
  }:DropdownContentProps) => {

  const context = useDropdownContext();
  const wrapperClasses = classNames("r-dropdown", className);

  return (
    <>
      {context.open &&
        <FloatingFocusManager context={context.floating.context}>
          <div ref={context.floating.floating}
               {...context.interactions.getFloatingProps(props)}
               className={wrapperClasses}
               style={{ position: context.floating.strategy, top: context.floating.y ?? 0, left: context.floating.x ?? 0 }}>
            {children}
          </div>
        </FloatingFocusManager>
      }
    </>);
}

const Dropdown = forwardRef((
  {
    children,
    placement = "bottom",
    offset = 6
  }: DropdownProps, forwardedRef) => {

  const floating = useFloatingDropdown({ placement, offset });

  useImperativeHandle(forwardedRef, () => ({
    close: () => floating.setOpen(false)
  }));

  return (<DropdownContext.Provider value={floating}>{children}</DropdownContext.Provider>);

});

export default Dropdown;

export namespace InnerDropdown {
  export const Trigger = DropdownTrigger;
  export const Content = DropdownContent;
}
