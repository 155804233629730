import React, {useEffect, useRef, useState} from "react";
import {hydrateRoot} from "react-dom/client";
import {ApolloProvider} from "@apollo/client";
import {HelmetProvider} from "react-helmet-async";
import {BrowserRouter} from "react-router-dom";
import {loadableReady} from "@loadable/component";
import {CookiesProvider} from "react-cookie";
import {AppConfigProvider, useIsMounted} from "@ct-react/core";
import {LocaleProvider, defineLocale, useLocaleContext} from "@ct-react/locale";
import {APP_CTX_SCRIPT_ID, APOLLO_CACHE_SCRIPT_ID} from "@shared/constants";
import {AppContext} from "@shared/models/app-context";
import {handleMomentJsLocale} from "@shared/locales";
import buildApolloClient from "@shared/gql/client";
import ScrollToTop from "./app/components/layout/scroll-to-top";
import App from "./app/app";

const loadingLocale = defineLocale(navigator.languages || [], window.location.pathname);

const Wrapper = () => {

  const { locale } = useLocaleContext();
  const isMounted = useIsMounted();
  const ctx = useRef<AppContext>(window.__APP_CTX__);
  const [ apolloClient, setApolloClient ] = useState(buildApolloClient(ctx.current, false, locale, window.__APOLLO_STATE__));

  useEffect(() => {
    if (!isMounted) return;
    setApolloClient(buildApolloClient(ctx.current, false, locale));
  }, [ locale ]);

  useEffect(() => {
    if (isMounted) return;
    delete (window as Partial<Window>).__APP_CTX__;
    const stateScriptTag = document.getElementById(APP_CTX_SCRIPT_ID);
    !!stateScriptTag && stateScriptTag.parentNode?.removeChild(stateScriptTag);
  }, [ ctx ]);

  useEffect(() => {
    if (isMounted) return;
    delete window.__APOLLO_STATE__;
    const stateScriptTag = document.getElementById(APOLLO_CACHE_SCRIPT_ID);
    !!stateScriptTag && stateScriptTag.parentNode?.removeChild(stateScriptTag);
  }, [ apolloClient ]);

  return (
    <CookiesProvider>
      <ApolloProvider client={apolloClient}>
        <AppConfigProvider value={ctx.current}>
          <HelmetProvider>
            <BrowserRouter>
              <ScrollToTop>
                <App />
              </ScrollToTop>
            </BrowserRouter>
          </HelmetProvider>
        </AppConfigProvider>
      </ApolloProvider>
    </CookiesProvider>);

}

loadableReady()
  .then(() => handleMomentJsLocale(loadingLocale, true))
  .then(() => hydrateRoot(
    document.getElementById("app")!,
    <LocaleProvider locale={loadingLocale} beforeLocaleChange={locale => handleMomentJsLocale(locale, true)}>
      <Wrapper />
    </LocaleProvider>));
