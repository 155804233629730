import { useRef } from "react";
import moment from "moment";

export const useSeason = () => {

  const today = useRef(moment());
  const summerLimit = useRef([
    moment(`${today.current.format("YYYY")}-04-01`),
    moment(`${today.current.format("YYYY")}-09-30`),
  ]);

  const isSummer = today.current.isBetween(summerLimit.current[0], summerLimit.current[1], "day", "[]");
  return isSummer ? "summer" : "winter";

}
