import { useLazyQuery, useQuery } from "@apollo/client";
import {useVisitor} from "@ct-react/visitor";
import {CART_BADGE_GQL_DATA} from "@shared/gql/queries";

export const useCartBadge = () => {
  const { isLoggedIn, ...variables } = useVisitor();
  const { data } = useQuery(CART_BADGE_GQL_DATA, { variables, fetchPolicy: "cache-and-network" });
  return data?.cart?.itemsCount ?? 0;
}

export const useLazyCartBadge = () => {
  const { isLoggedIn, ...variables } = useVisitor();
  const [ getCount ] = useLazyQuery(CART_BADGE_GQL_DATA, { variables, fetchPolicy: "cache-and-network" });
  return () => new Promise<number>((resolve, reject) => getCount()
    .then(({ data }) => !!data?.cart?.itemsCount
      ? resolve(data?.cart?.itemsCount)
      : reject())
    .catch(() => reject()));
}
