import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import moment from "moment-timezone";
import {defineMessages, useIntl} from "react-intl";
import {gql, useLazyQuery, useQuery} from "@apollo/client";
import {IconEdit, IconLoader3, IconSquareRoundedXFilled, IconX} from "@tabler/icons-react";
import Skeleton from "react-loading-skeleton";
import {
  autoUpdate,
  offset,
  useClick,
  useDismiss,
  useFloating,
  useInteractions
} from "@floating-ui/react";
import {
  AnyPeriod,
  BookPeriod,
  buildPeriod,
  classNames,
  isBookPeriod,
  isPriceWithExtras,
  isPeriodValid,
  Period,
  useAppConfig,
  useIsMounted, buildPriceWithExtras
} from "@ct-react/core";
import {RangePickerController} from "@ct-react/calendar";
import {agencyTranslations, bookingTranslations} from "../../i18n/sharable-defs";
import {useDisplayDesktop} from "../../tools/breakpoints";
import {isotize, momentize} from "../../tools/moments";
import {Button} from "../common/minimals";
import PeriodInputResume from "./booking/period-input-resume";
import BookPriceResume from "./booking/book-price-resume";
import "./booking-box.scss";
import {formatPeriod} from "../../hooks/format";

type OptDay = { day: moment.Moment }
type OptCheckOut = OptDay & { bookable: boolean; }
type OptCheckIn = OptDay & { offset: number, checkOuts: OptCheckOut[]; }
type BookingOptions = { range: Period; checkIns: OptCheckIn[]; }

enum DisplayState { Empty, Processing, OnEdit, Conflicted, Bookable, Contactable }

const ALL_OPTIONS_GQL_DATA = gql`
  query GetBookingOptions($articleId: ID!) {
    options: bookingAccommodationOptions(articleId: $articleId) { period, checkInConfigs { day, dayOffset, checkOutConfigs { day, bookable } } }
  }
`;

const ALL_BOOKED_DAYS_GQL_DATA = gql`
  query GetBookedDates($articleId: ID!) {
    bookedDates: bookingAccommodationBookedDates(articleId: $articleId)
  }
`;

const LAZY_PRICE_GQL_DATA = gql`
  query GetBookingPrice($articleId: ID!, $checkIn: Date!, $checkOut: Date!) {
    selectionPrice: bookingAccommodationPrice(articleId: $articleId, checkIn: $checkIn, checkOut: $checkOut) {
      bookable priceOnDemand price { amount original currency } extras { id type amount } }
  }
`;

const transDefs = defineMessages({
  clean: { id: "booking-picker-clear-choice-button", defaultMessage: "Effacer les dates" },
  cancel: { id: "booking-picker-cancel-button", defaultMessage: "Fermer" },
  close: { id: "booking-picker-close-button", defaultMessage: "Valider" },
  onDemand: { id: "booking-picker-ondemand-textual", defaultMessage: "Disponible sur demande uniquement. Faites suite en contactant l'agence directement." },
  conflict: { id: "booking-picker-conflict", defaultMessage: "Cette période est nouvellement devenue indisponible." }
});

type ConflictableBookPeriod = BookPeriod & { conflicted?: true };

type BookingBoxProps = {
  articleId: string;
  mobileView: boolean;
  processing?: boolean;
  bookingChoice?: AnyPeriod;
  onBookingChoiceChange?: (bookingChoice: AnyPeriod) => void;
  onAction?: (bookingChoice: BookPeriod) => void;
}

const BookingBox = (
  {
    articleId,
    mobileView,
    processing = false,
    bookingChoice : initedBookingChoice = buildPeriod(),
    onBookingChoiceChange = () => void 0,
    onAction: onCallbackAction = () => void 0
  }: BookingBoxProps) => {

  const intl = useIntl();
  const isMounted = useIsMounted();
  const today = moment.utc();
  const { options: { blockCart } } = useAppConfig();
  const pickerConfig = { onlineRestrictedLabel: intl.formatMessage(bookingTranslations.onDemandOnly) };

  // inited state

  const initedBookingSelection = isPeriodValid(initedBookingChoice) ? initedBookingChoice as BookPeriod : undefined;
  const initedPickerFocus = isPeriodValid(initedBookingChoice) ? null : "start";

  // component states

  const [ displayState, setDisplayState ] = useState<DisplayState>(DisplayState.Processing);
  const [ bookingSelection, setBookingSelection ] = useState<ConflictableBookPeriod | undefined>(initedBookingSelection);
  const [ pickerFocus, setPickerFocus ] = useState<any>(initedPickerFocus);
  const [ pickerRange, setPickerRange ] = useState<Period>(initedBookingChoice!);
  const [ bookingOptions, setBookingOptions ] = useState<BookingOptions | null>(null);
  const [ bookedDates, setBookedDates ] = useState<moment.Moment[]>([]);

  const wrapperRef = useRef<HTMLDivElement | null>(null);

  // mount / unmount

  useEffect(() => {
    isPeriodValid(initedBookingChoice) && fetchPrice().then();
    return () => stopBookedDatesPolling();
  }, []);

  // data loading

  const { loading: loadingOptions } = useQuery(ALL_OPTIONS_GQL_DATA, {
    variables: { articleId },
    ssr: false,
    onCompleted: ({ options: result }) => {
      setBookingOptions({
        range: {
          start: momentize(result.period[0]),
          end: momentize(result.period[1])
        },
        checkIns: result.checkInConfigs.map(chIn => ({
          day: momentize(chIn.day),
          offset: chIn.dayOffset,
          checkOuts:  chIn.checkOutConfigs.map(chOut => ({ ...chOut, day: momentize(chOut.day) }))
        }))
      })
    }
  });

  const { stopPolling: stopBookedDatesPolling } = useQuery(ALL_BOOKED_DAYS_GQL_DATA, {
    variables: { articleId },
    ssr: false,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "no-cache",
    pollInterval: 30 * 1000,
    onCompleted: ({ bookedDates: result }) => {
      const data = result.map(momentize);
      setBookedDates(data);
      if (!isPeriodValid(bookingSelection)) return;

      const withConflict = data.some(d => d.isBetween(bookingSelection!.start, bookingSelection!.end, "day", "[)"));
      if (withConflict) {
        setPickerRange(buildPeriod());
        setBookingSelection(prev => ({ ...prev!, conflicted: true }));
      } else if (!!bookingSelection?.conflicted) {
        const { conflicted, ...next } = bookingSelection;
        setPickerRange(bookingSelection);
        setBookingSelection(next);
      }
    }
  });

  const [ getPrice, { loading: priceLoading } ] = useLazyQuery(LAZY_PRICE_GQL_DATA, {
    ssr: false,
    fetchPolicy: "no-cache",
    onCompleted: ({ selectionPrice: result }) => {
      !!result.price && delete result.price.__typename;
      (!!result.price && !result.price.original) && delete result.price.original;
      const selection = {
        ...(bookingSelection || pickerRange),
        bookable: result.bookable,
        price: result.priceOnDemand ? "onDemand" : buildPriceWithExtras(result.price, result.extras)
      } as BookPeriod;
      setBookingSelection(selection);
      onBookingChoiceChange!(selection);
    }
  });

  // component logics

  // filter all booking options with booked dates for calendar availability

  const availableOptions = useMemo(() => {
    if (!bookingOptions) return [];
    return bookingOptions.checkIns
      .filter(chIn => {
        const arrival = chIn.day.clone().subtract(chIn.offset, "day");
        const comparable = bookedDates.filter(bd => bd.isAfter(chIn.day, "day"));
        const validOffset = arrival.isSame(today, "day")
          ? today.tz("Europe/Zurich").hour() < 17
          : arrival.isAfter(today, "day");
        const validCheckouts = chIn.checkOuts.some(chOut => comparable.every(bd => bd.isSameOrAfter(chOut.day, "day")));
        return validOffset && validCheckouts;
      })
      .map(chIn => {
        const checkOutLimit = bookedDates.find(bd => bd.isAfter(chIn.day, "day") && chIn.checkOuts.find(d => d.day.isSame(bd, "day")))
          || (!!chIn ? moment.max(chIn.checkOuts.map(d => d.day)) : undefined);
        return {
          ...chIn,
          checkOuts: chIn.checkOuts.filter(chOut => !checkOutLimit || chOut.day.isSameOrBefore(checkOutLimit, "day"))
        };
      });
  }, [ bookingOptions, bookedDates ]);

  // picker day blocking logic

  const isPickerDayBlocked = (day: moment.Moment) => {

    const checkBooked = bookedDates.some(bd => bd.isSame(day, "day"));
    if (availableOptions.length === 0) return checkBooked ? "booked" : true;

    const checkInRef = pickerFocus === "end" ? pickerRange.start : day;
    const matchedCheckIn = availableOptions.find(chIn => chIn.day.isSame(checkInRef, "day"));

    // for check_out selection
    if (pickerFocus === "end") {
      const matchedCheckOut = matchedCheckIn?.checkOuts.find(chOut => chOut.day.isSame(day, "day"));
      if (!matchedCheckOut) return true;
      return !matchedCheckOut.bookable ? "online-restricted" : false;
    }

    // for check_in selection;
    if (checkBooked) return "booked";
    if (!matchedCheckIn) return true;
    return matchedCheckIn.checkOuts.every(d => !d.bookable) ? "online-restricted" : false;

  }

  // lazy request fetching price

  const fetchPrice = useCallback(async () => await getPrice({
    variables: {
      articleId,
      checkIn: isotize(pickerRange.start!),
      checkOut: isotize(pickerRange.end!)
    }
  }), [ pickerRange ]);

  // react on period selection

  useEffect(() => {
    if (!isMounted) return;
    if (!!bookingSelection && !isPeriodValid(pickerRange)) setBookingSelection(undefined);
    if (pickerFocus === "end") return;
    if (isPeriodValid(pickerRange)) fetchPrice().then();
    else onBookingChoiceChange!(pickerRange);
  }, [ pickerFocus ]);

  // picker floating display

  const [ pickerOpen, setPickerOpen ] = useState<boolean>(false);

  const { x, y, reference, floating, strategy, context } = useFloating({
    open: pickerOpen,
    onOpenChange: setPickerOpen,
    strategy: mobileView ? "fixed" : "absolute",
    placement: mobileView ? "top-end" : "bottom-end",
    whileElementsMounted: autoUpdate,
    middleware: [
      offset(({ placement, rects }) =>  placement.match(/^top/) ? 0 : -1 * rects.reference.height)
    ]
  });

  const { getReferenceProps, getFloatingProps } = useInteractions([
    useClick(context, { toggle: true }),
    useDismiss(context, { outsidePress: !mobileView })
  ]);

  // state effects

  useEffect(() => {
    if (processing || loadingOptions) {
      setDisplayState(DisplayState.Processing);
      return;
    }
    if (!!bookingSelection) {
      if (!!bookingSelection.conflicted) setDisplayState(DisplayState.Conflicted);
      else if (!bookingSelection.bookable) setDisplayState(DisplayState.Contactable);
      else setDisplayState(DisplayState.Bookable);
      return;
    }
    setDisplayState(pickerOpen ? DisplayState.OnEdit : DisplayState.Empty);
  }, [ processing, bookingSelection, loadingOptions, pickerOpen ]);


  // dom interactions

  const onReset = () => {
    setPickerFocus("start");
    setPickerRange(buildPeriod());
    setBookingSelection(undefined);
  }

  const onAction = () => {
    if ([ DisplayState.Bookable, DisplayState.Contactable ].includes(displayState) && isBookPeriod(bookingSelection)) {
      if (displayState === DisplayState.Contactable && pickerOpen) setPickerOpen(false);
      onCallbackAction!(bookingSelection);
      return;
    }
    if (displayState === DisplayState.Contactable && pickerOpen) {
      setPickerOpen(false);
      return;
    }
    if ([ DisplayState.Empty, DisplayState.Conflicted ].includes(displayState)) {
      mobileView && wrapperRef.current?.scrollIntoView({ block: "end" });
      setPickerOpen(prev => !prev);
      return;
    }
  }

  const onResume = () => {
    if (pickerOpen || !mobileView) return;
    mobileView && wrapperRef.current?.scrollIntoView({ block: "end" });
    !pickerOpen && setPickerOpen(true);
  }


  // rendering

  const isDesktop = useDisplayDesktop();
  const compactView = !mobileView && !isDesktop;

  const wrapperClasses = classNames("rla-book-choice", { "fixed": mobileView });
  const actionClasses = classNames("action-button", "bolder", { large: !compactView && !mobileView });
  const resumeWrapperClasses = classNames("resume-wrapper", { conflicted: displayState === DisplayState.Conflicted });
  const pickerWrapperClasses = classNames("rla-book-picker-float-wrapper", { mobile: mobileView });

  const disableAction = displayState === DisplayState.Processing ||
    (displayState === DisplayState.Bookable && blockCart) ||
    (displayState === DisplayState.OnEdit && !bookingSelection);

  const actionContentRendering = useCallback(() => {
    switch (displayState) {
      case DisplayState.Processing: return <IconLoader3 className="icon-loading" />;
      case DisplayState.Contactable: return intl.formatMessage(agencyTranslations.contact);
      case DisplayState.Bookable: return intl.formatMessage(bookingTranslations.book);
      default: return intl.formatMessage(bookingTranslations.availabilities)
    }
  }, [ displayState ]);

  if (mobileView)
    return (
     <>
       <div ref={wrapperRef}
            className={wrapperClasses}>

         <div className="book-price-resume">
           {(() => {
             if (priceLoading) return (
               <>
                 <Skeleton containerClassName="price" inline={true} width="55%" />
                 <Skeleton containerClassName="period" inline={true} width="85%" />
               </>);
             if (isBookPeriod(bookingSelection)) return (
               <>
                 <span className={classNames("price")}>
                   {bookingSelection.price === "onDemand"
                     ? intl.formatMessage(bookingTranslations.fullPriceOnDemand)
                     : intl.formatNumber(isPriceWithExtras(bookingSelection.price)
                       ? bookingSelection.price.fullAmount
                       : bookingSelection.price.amount, {
                       style: "currency",
                       currency: bookingSelection.price.currency
                     })
                   }
                 </span>
                 <span className={classNames("period", {conflict: displayState === DisplayState.Conflicted})}
                       onClick={onResume}>
                   {formatPeriod(intl, bookingSelection as Period, true)}
                   <IconEdit/>
                 </span>
               </>);
             return intl.formatMessage(bookingTranslations.periodPlaceholder);
           })()}
         </div>

         <Button ref={reference}
                 {...getReferenceProps()}
                 type="button"
                 className={actionClasses}
                 onClick={onAction} disabled={disableAction}>
           {actionContentRendering()}
         </Button>

       </div>
       {pickerOpen &&
         <div ref={floating}
              {...getFloatingProps()}
              className={pickerWrapperClasses}
              style={{position: strategy, top: y ?? 0, left: x ?? 0}}>

           <div className="pickable-header">
             <Button type="button" className="link rounded-icon" onClick={() => setPickerOpen(false)}>
               <IconX/>
             </Button>
             <Button type="button" className="link small" onClick={onReset}>
               {intl.formatMessage(transDefs.clean)}
             </Button>
           </div>

           <div className="pickable-cal">
             <RangePickerController orientation={(mobileView ? "vertical" : "horizontal")}
                                    numberOfMonths={mobileView ? 1 : 2}
                                    advancedBlockageDisplay={pickerConfig}
                                    minDate={bookingOptions?.range.start}
                                    maxDate={bookingOptions?.range.end}
                                    selectedRange={pickerRange}
                                    pickFocus={pickerFocus}
                                    onSelectedRangeChange={setPickerRange}
                                    onPickFocusChange={setPickerFocus}
                                    isDayBlocked={isPickerDayBlocked}/>
           </div>

         </div>
       }
     </>);

  return (
    <>
      <div ref={wrapperRef}
           className={wrapperClasses}>

        <button className="resume-period-wrapper"
                onClick={() => setPickerOpen(prev => !prev)}
                disabled={displayState === DisplayState.Processing}>
          <PeriodInputResume period={bookingSelection} compactView={compactView}/>
        </button>

        <Button ref={reference}
                {...getReferenceProps()}
                type="button" className={actionClasses}
                onClick={onAction} disabled={disableAction}>
          {actionContentRendering()}
        </Button>

        {isBookPeriod(bookingSelection) &&
          <>
            {(displayState === DisplayState.Conflicted) &&
              <span className="conflicted">
                <IconSquareRoundedXFilled />
                {intl.formatMessage(transDefs.conflict)}
              </span>
            }
            {isBookPeriod(bookingSelection) &&
              <>
                <BookPriceResume data={bookingSelection} />
                {!bookingSelection.bookable &&
                  <span className="textual-ondemand">{intl.formatMessage(transDefs.onDemand)}</span>
                }
              </>
            }
          </>
        }

      </div>
      {pickerOpen &&
        <div ref={floating}
          {...getFloatingProps()}
             className={pickerWrapperClasses}
             style={{ position: strategy, top: y ?? 0, left: x ?? 0 }}>

          <div className="pickable-header">
            <div className="priced-choice">
              {priceLoading &&
                <>
                  <Skeleton className="period" width="50%" />
                  <Skeleton className="price" width="50%" />
                </>
              }
              {isBookPeriod(bookingSelection) &&
                <>
                  <span className="period">
                    {intl.formatMessage(bookingTranslations.nightsPeriod, {
                      nightsCount: bookingSelection.end.diff(bookingSelection.start, "day")
                    })}
                  </span>
                  <span className="price">
                    {bookingSelection.price === "onDemand"
                      ? intl.formatMessage(bookingTranslations.fullPriceOnDemand)
                      : intl.formatNumber(isPriceWithExtras(bookingSelection.price)
                          ? bookingSelection.price.fullAmount
                          : bookingSelection.price.amount, {
                        style: "currency",
                        currency: bookingSelection.price.currency
                      })
                    }
                  </span>
                </>
              }
            </div>
            <PeriodInputResume period={bookingSelection} />
          </div>

          <div className="pickable-cal">
            <RangePickerController orientation={(mobileView ? "vertical" : "horizontal")}
                                   numberOfMonths={mobileView ? 1 : 2}
                                   advancedBlockageDisplay={pickerConfig}
                                   minDate={bookingOptions?.range.start}
                                   maxDate={bookingOptions?.range.end}
                                   selectedRange={pickerRange}
                                   pickFocus={pickerFocus}
                                   onSelectedRangeChange={setPickerRange}
                                   onPickFocusChange={setPickerFocus}
                                   isDayBlocked={isPickerDayBlocked}/>
          </div>

          <div className="pickable-footer">
            <Button type="button" className="link small" onClick={onReset}>
              {intl.formatMessage(transDefs.clean)}
            </Button>
            <Button type="button" className="secondary small" onClick={() => setPickerOpen(false)}>
              {intl.formatMessage(isPeriodValid(pickerRange) ? transDefs.close : transDefs.cancel)}
            </Button>
          </div>

        </div>
      }
    </>);

}

export default BookingBox;
