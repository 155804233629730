import React from "react";
import {useIntl} from "react-intl";
import {classNames} from "@ct-react/core";
import {LocaleLink, useNavigate} from "@ct-react/locale";
import {buildAgencyUrl} from "../../../shared/urls";
import {agencyTranslations} from "../../i18n/sharable-defs";
import {SkeletonableProps} from "../../tools/components";
import {Agency} from "../../models/agency";
import {Button, ClampText} from "../common/minimals";
import "./agency.scss";
import AgencyContactBox from "./agency-contact-box";
import Skeleton from "react-loading-skeleton";

type AgencyCardProps = SkeletonableProps<Agency>;

const AgencyCard = (
  {
    loading,
    className,
    data
  }: AgencyCardProps) => {

  const intl = useIntl();
  const navigate = useNavigate();

  const wrapperClasses = classNames("a-card", className);

  if (loading || !data)
    return (
      <div className={wrapperClasses}>
        <div className="metas">
          <Skeleton className="logo" height="4rem" />
          <div className="about">
            <h3><Skeleton width="25%" /></h3>
            <Skeleton inline={true} count={3} />
            <Skeleton inline={true} width="50%" />
          </div>
        </div>
        <Skeleton className="cbox-skeleton" />
      </div>);

  const url = buildAgencyUrl(data);

  return (
    <div className={wrapperClasses}>
      <div className="metas">

        <div className={classNames("logo", { empty: !data.logo })}>
          <LocaleLink to={url}>
            {!!data.logo
              ? <img src={data.logo} alt={data.name} />
              : data.name
            }
          </LocaleLink>
        </div>

        {(!!data.lead && data.lead.value.length > 0) &&
          <div className="about">
            <h3>{intl.formatMessage(agencyTranslations.about)}</h3>
            <ClampText text={data.lead.value} maxChar={160} expandable={false} />
          </div>
        }

        <Button className="more tertiary small bolder"
                onClick={() => navigate(url)}>
          {intl.formatMessage({ id: "generics-more-open", defaultMessage: "En savoir plus" })}
        </Button>
      </div>

      <AgencyContactBox data={data}
                        onContact={() => navigate(url)} />

    </div>);
}

export default AgencyCard;
