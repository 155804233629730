import React from "react";
import {defineMessages, useIntl} from "react-intl";
import {AnyPrice, BookPeriod, classNames, isPriceWithExtras} from "@ct-react/core";
import {bookingTranslations} from "../../../i18n/sharable-defs";
import {DataProps} from "../../../tools/components";
import "./book-price-resume.scss";
import Price from "../../common/price";
import Tooltip, { InnerTooltip } from "../../common/tooltip";
import {IconInfoSquareRoundedFilled} from "@tabler/icons-react";

type BookPriceResumeProps = DataProps<BookPeriod>;

const BookPriceResume = (
  {
    className,
    data
  }: BookPriceResumeProps) => {

  const intl = useIntl();

  const wrapperClasses = classNames("rla-book-price-resume", className);

  return (
    <div className={wrapperClasses}>

      {(data.price !== "onDemand" && isPriceWithExtras(data.price)) &&
        <>
          <dl className="price-list">
            <dt>
              {intl.formatMessage(bookingTranslations.nightsPeriod, {
                nightsCount: data.end.diff(data.start, "day")
              })}
            </dt>
            <dd><Price data={data.price}/></dd>
          </dl>
          {data.price.extras.map((ex: any, i) =>
            <dl key={i} className="price-list">
              <dt>
                {(() => {
                  switch (ex.type) {
                    case "CUSTOMER_CHARGE":
                      return (
                        <Tooltip placement="bottom">
                          <InnerTooltip.Trigger as="span" className="tooltip-more">
                            {intl.formatMessage(bookingTranslations.customerCharge)}
                            <IconInfoSquareRoundedFilled />
                          </InnerTooltip.Trigger>
                          <InnerTooltip.Content>{intl.formatMessage(bookingTranslations.customerChargeTooltip)}</InnerTooltip.Content>
                      </Tooltip>);
                    default:
                      return null;
                  }
                })()}
              </dt>
              <dd>{intl.formatNumber(ex.amount, {
                style: "currency",
                currency: (data.price as AnyPrice).currency
              })}</dd>
            </dl>)
          }
        </>
      }
      <dl className="price-list total">
        <dt>{intl.formatMessage(bookingTranslations.total)}</dt>
        <dd>
          {data.price === "onDemand"
            ? intl.formatMessage(bookingTranslations.fullPriceOnDemand)
            : <Price data={data.price} total={true} />
          }
        </dd>
      </dl>

    </div>);

};

export default BookPriceResume;
