import {AppConfig} from "@ct-react/core";

export type AppContext = Readonly<AppConfig> & {
  readonly gqlAppName: string;
  readonly gqlClientUrl: string;
}

export enum CustomViewType {
  HOTEL = "hotel"
}
