import moment from "moment";

export const momentize = (day: string): moment.Moment => moment(day, "YYYY-MM-DD");
export const isotize = (day: moment.Moment): string => day.format("YYYY-MM-DD");

export const defineMonths = (initialMonth: moment.Moment, numberOfMonths: number): moment.Moment[] => {
  const ref = initialMonth.clone().startOf("month");
  return Array.from(
    { length: numberOfMonths },
    (_, i) => ref.clone().add(i, "month"));
}
