import React from "react";
import {useIntl} from "react-intl";
import {IconInfoSquareRoundedFilled} from "@tabler/icons-react";
import {CartPrice} from "../../models/cart";
import "./last-minute-warning.scss";

const LastMinuteWarning = ({ price }: { price: CartPrice }) => {

  const intl = useIntl();

  if (price.amount !== price.downPayment)
    return null;

  return (
    <div className="last-minute-warning">
      <IconInfoSquareRoundedFilled />
      {intl.formatMessage({
        id: "cart-last-minute-warning",
        defaultMessage: "La montant total devra être payé immédiatement."
      })}
    </div>);

}

export default LastMinuteWarning;
