import React, { MouseEvent, useEffect, useMemo, useRef, useState } from "react";
import moment from "moment";
import {useIntl} from "react-intl";
import {IconCalendar, IconChevronLeft, IconChevronRight, IconDiscount2} from "@tabler/icons-react";
import {useFloating, useHover, useInteractions} from "@floating-ui/react";
import {
  AnyPrice,
  buildPriceWithExtras,
  classNames,
  debounce,
  isPriceWithExtras,
  useIsMounted
} from "@ct-react/core";
import {LocaleLink, useNavigate} from "@ct-react/locale";
import {buildArticleUrl} from "@shared/urls";
import {bookingTranslations} from "../../i18n/sharable-defs";
import {formatDiscountPeriod, formatPeriod} from "../../tools/display";
import {Ratio} from "../../models/images";
import {ArticleSummary, Discount} from "../../models/article";
import RatioImage from "../common/ratio-image";
import ResumeFeatures from "./resume-features";
import "./summary.scss";
import { DataProps } from "../../tools/components";

export type BookingSuggestionEntry = {
  checkIn: string;
  checkOut: string;
  priceOnDemand: boolean;
  price?: AnyPrice;
  extras?: { amount: number }[];
}

type SummaryProps = ArticleSummary & {
  bookingSuggestion?: BookingSuggestionEntry;
  ratio?: Ratio;
  view?: "card" | "map";
  fixedDiscountView?: boolean;
}

type DisplayDiscountProps = DataProps<Discount<moment.Moment>[]> & {
  fixed?: boolean;
  onClick: (period: [ moment.Moment, moment.Moment]) => void;
}

const DisplayDiscount = (
  {
    data,
    fixed = false,
    onClick
  }: DisplayDiscountProps) => {

  const intl = useIntl();
  const [ visible, setVisible ] = useState(fixed);

  // floating

  const { refs, context } = useFloating({
    open: visible,
    ...(!fixed) && { onOpenChange: setVisible }
  });

  const { getReferenceProps, getFloatingProps } = useInteractions([
    useHover(context, { move: false })
  ]);

  // dom interactions

  const blockEvent = (e: MouseEvent<HTMLDivElement>) => e.preventDefault();

  const onDiscountClick = (discount: Discount<moment.Moment>, e: MouseEvent<HTMLDivElement>) => {
    if (discount.definitionType === "FIXED") {
      e.preventDefault();
      onClick(discount.period);
    }
  }

  const wrapperClasses = classNames("discount-wrapper", { visible });

  return (
    <div ref={refs.setReference}
         {...getReferenceProps()}
         className={wrapperClasses}>
      <div className="discount-box"
           onClick={blockEvent}><IconDiscount2 /></div>
      {visible &&
        <div ref={refs.setFloating}
             {...getFloatingProps()}
             className="discounts-list">
          {data.slice(0, 3).map((discount, i) => (
            <div key={i}
                 className="one-discount"
                 onClick={onDiscountClick.bind(this, discount)}>
              <div className="value">
                {intl.formatMessage(bookingTranslations.discount, { val:
                    <span className="value">
                      {discount.type === "FIXED"
                        ? intl.formatNumber(discount.fixedValue.amount, { style: "currency", currency: discount.fixedValue.currency })
                        : discount.percentageValue * 100 + "%" }
                    </span>
                })}
              </div>
              <div className="period">
                {discount.definitionType === "FIXED"
                  ? <>
                    <IconCalendar />
                    {formatPeriod(intl, discount.period[0], discount.period[1])}
                  </>
                  : <span>{formatDiscountPeriod(intl, discount.period[0], discount.period[1])}</span>
                }
              </div>
              {discount.definitionType === "FIXED" &&
                <div className="targetable">
                  <IconChevronRight />
                </div>
              }
            </div>)
          )}
        </div>
      }
    </div>);

};

const Summary = (
  {
    discounts = [],
    bookingSuggestion,
    ratio = Ratio.FULL_CARD,
    view = "card",
    fixedDiscountView = false,
    ...props
  }: SummaryProps) => {

  const intl = useIntl();
  const isMounted = useIsMounted();
  const navigate = useNavigate();
  const isMapView = view === "map";

  // component refs

  const sliderRef = useRef<HTMLDivElement>(null);
  const sliderTrackRef = useRef<HTMLDivElement>(null);
  const bulletsRef = useRef<HTMLDivElement>(null);
  const bulletTrackRef = useRef<HTMLUListElement>(null);
  const bulletRef = useRef<HTMLLIElement>(null);

  // component states

  const [ scrollIndex, setScrollIndex ] = useState(0);
  const [ scrollLength, setScrollLength ] = useState(props.images.length);

  useEffect(() => {
    if (!isMounted) return;
    setScrollIndex(0);
    setScrollLength(props.images.length);
  }, [ props.images ]);

  useEffect(() => {
    if (!isMounted) return;
    bulletTrackRef.current!.scroll({
      behavior: "smooth",
      left: Math.max(scrollIndex -2) * bulletRef.current!.scrollWidth
    })
  }, [ scrollIndex ]);

  const target = useMemo(() => buildArticleUrl(props) +
    (!bookingSuggestion ? "" : `&checkin=${bookingSuggestion.checkIn}&checkout=${bookingSuggestion.checkOut}`), [ props.id, bookingSuggestion ]);

  const typedDiscounts = useMemo((): Discount<moment.Moment>[] => {
    if (!discounts) return [];
    return discounts.map(d => ({
      ...d,
      period: [ moment(d.period[0]).startOf("day"),  moment(d.period[1]).startOf("day") ]
    }));
  }, [ discounts ]);

  const typedSuggestion = useMemo((): any | undefined => {
    if (!bookingSuggestion) return undefined;
    return {
      checkIn: moment(bookingSuggestion.checkIn).startOf("day"),
      checkOut: moment(bookingSuggestion.checkOut).startOf("day"),
      priceOnDemand: bookingSuggestion.priceOnDemand,
      ...(!!bookingSuggestion.price) && { price: buildPriceWithExtras(bookingSuggestion.price, bookingSuggestion.extras) }
    }
  }, [ bookingSuggestion ]);


  // dom interactions

  const onDiscountClick = (period: [ moment.Moment, moment.Moment]) =>
      navigate(buildArticleUrl(props) + `&checkin=${period[0].format("YYYY-MM-DD")}&checkout=${period[1].format("YYYY-MM-DD")}`);

  const onSliderScroll = debounce(() => {
    const newIndex = Math.round(sliderTrackRef.current!.scrollLeft / sliderRef.current!.scrollWidth);
    setScrollIndex(newIndex);
  }, 125);

  const onSliderNav = (e, direction: -1 | 1) => {
    e.preventDefault();
    if ((direction === -1 && scrollIndex === 0) || (direction === 1 && scrollIndex + 1 === scrollLength))
      return;
    sliderTrackRef.current!.scroll({
      behavior: "smooth",
      left:  sliderTrackRef.current!.scrollLeft + (direction * sliderRef.current!.scrollWidth)
    });
  }

  // rendering

  const wrapperClasses = classNames("rn-card", { "map-view": isMapView });
  const sliderTrackClasses = classNames("slider-track");
  const sliderBulletsClasses = classNames("slider-bullets");

  return (
    <article className={wrapperClasses}>
      <LocaleLink to={target}>

        <div ref={sliderRef}
             className="slider">
          <div ref={sliderTrackRef}
               className={sliderTrackClasses}
               onScrollCapture={onSliderScroll}>
            {props.images.map((image, i) =>
              <RatioImage key={i}
                          className="slider-item"
                          images={image}
                          alt={props.title.value}
                          ratio={ratio} />
            )}
          </div>
          <button className={classNames("slider-arrow", "left", { mask: scrollIndex === 0 })}
                  onClick={e => onSliderNav(e,-1)}>
            <IconChevronLeft />
          </button>
          <button className={classNames("slider-arrow", "right", { mask: scrollIndex + 1 === scrollLength })}
                  onClick={e => onSliderNav(e, 1)}>
            <IconChevronRight />
          </button>
        </div>

        <div className="resume">

          <div ref={bulletsRef}
               className={sliderBulletsClasses}>
            <ul ref={bulletTrackRef}
                className="slider-bullets-track">
              {props.images.map((_, i) =>
                <li key={i}
                    {...(i === 0) && { ref: bulletRef }}
                    className={classNames("slider-bullets-item", { active: i === scrollIndex })} />)}
            </ul>
          </div>

          {!!typedSuggestion &&
            <div className={classNames("book-date", { discounted: !!typedSuggestion.price?.original })}>
              <IconCalendar />
              {formatPeriod(intl, typedSuggestion.checkIn, typedSuggestion.checkOut)}
              &nbsp;&nbsp;|&nbsp;&nbsp;
              {typedSuggestion.priceOnDemand
                ? intl.formatMessage(bookingTranslations.priceOnDemand)
                : intl.formatNumber(isPriceWithExtras(typedSuggestion.price) ? typedSuggestion.price.fullAmount : typedSuggestion.price!.amount, {
                  style: "currency",
                  currency: typedSuggestion.price!.currency
                })
              }
            </div>
          }

          <h1>{props.title.value}</h1>
          {!isMapView && <ResumeFeatures {...props.features} />}

        </div>

        {(view === "card" && typedDiscounts.length > 0) &&
          <DisplayDiscount data={typedDiscounts}
                           fixed={fixedDiscountView}
                           onClick={onDiscountClick} />
        }

      </LocaleLink>
    </article>);

}

export default Summary;
